import { useEffect, useState } from "react";
import { IRefTypes } from "types/types";

export const useOnScreen = (ref:IRefTypes) => {
    const [isIntersecting, setIntersecting] = useState(false);
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    useEffect(() => {
        if (!ref.current) return
        observer.observe(ref.current)
        return () => { observer.disconnect() }
    // eslint-disable-next-line
    }, [])

    return isIntersecting;
}