import { configureStore, ThunkAction, Action, Reducer, combineReducers, Dispatch, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import SocketClient from 'api/SocketClient';
import appSlice from 'features/App/app.slice';
import socketMiddleware from 'middleware/socketMiddleware';
import { IAppState } from 'utilities/types';

const socket = new SocketClient();
export interface RootState {
  app: IAppState;
}

export const rootReducer: Reducer<RootState> = combineReducers<RootState>({
  app: appSlice,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), socketMiddleware(socket)]
});

export type AppDispatch = Dispatch<AnyAction> & ThunkDispatch<RootState, null, AnyAction> 
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;