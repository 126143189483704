import React, { lazy, Suspense } from 'react';
import FoundationHeader from './FoundationHeader';
import './Foundation.scss';

const Mission = lazy(() => import('./Mission'));
const Objectives = lazy(() => import('./Objectives'));

const Foundation = () => {
  return (
    <div className='foundation'>
      <FoundationHeader/>
      <Suspense fallback={<></>}>
        <Mission />
        <Objectives />
      </Suspense>
    </div>
  )
}

export default Foundation;