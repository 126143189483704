import React from 'react';
import { useNavigate } from 'react-router';
import './Logo.scss';
import {isFoundationWebsite} from "../../../config/config";

type ILogoTypes = {
    className?: string;
    linkTo?: string;
}

const Logo = ({className, linkTo}:ILogoTypes) => {
  const navigate = useNavigate();
  return (
    <div className={`logo_component ${className ?? ''}`}>
       <div 
        className={`logo${isFoundationWebsite && false ? ' foundation-logo': ''}`}
        title='COTI'
        {...linkTo ? {onClick: () => linkTo && navigate(linkTo), style: { cursor: 'pointer' }} : {}}
      />
      {isFoundationWebsite && <span>foundation</span>}
    </div>
  )
}

export default Logo;