import React, { useMemo } from 'react';
import './AnimatedValue.scss'

interface IAnimatedValue {
  value: string;
  className?: string;
  prefix?: string;
  suffix?: string;
}

const AnimatedValue = ({ className, value, prefix, suffix }:IAnimatedValue) => {
  const digits = value.toString().split('')

  return useMemo(() => (
        <p className={`digits ${className ?? ''}`}>
          {prefix && <span className='prefix'>{prefix}</span>}

          {digits && digits.map((digit, idx) => {
            const isNumber = /^[0-9]+$/.test(digit)
            if (isNumber) {
              return <NumberDigit key={idx} digit={Number(digit)}/>
            }
            return <span key={idx}>{digit}</span>
          })}

          {suffix && <span className='suffix'>{suffix}</span>}
        </p>
  ), [className, digits, prefix, suffix]);
};

export default AnimatedValue;

const NumberDigit = ({ digit }: { digit: number }) => useMemo(() => {
  return (
  <span className="digit-container">
    <span className="digit" style={{ transform: `translateY(-${digit*10}%)` }}>
      0<br />1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
    </span>
  </span>
)}, [digit])