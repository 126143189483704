import React, { lazy, Suspense, useMemo } from 'react';
import Section from 'components/Elements/Section';
import Image from 'components/Elements/Image';
import { SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import { useAppSelector } from 'redux/hooks';
import { selectApp } from 'features/App/app.slice';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './Team.scss';

const TeamMembers = lazy(() => import('./TeamMembers'));

const Team = () => {
  return useMemo(() => (
    <div className='team'>
        <OurEmployees/>
        <Suspense fallback={<></>}>
          <TeamMembers/>
        </Suspense>
        <BlogCTA/>
    </div>
  ), [])
}

export default Team;

const OurEmployees = () => {
    const { ourEmployees } = config.team
    const {windowDimensions: {isMobile, isPortraitTablet}} = useAppSelector(selectApp);
    const image = `${ourEmployees.image}${(isPortraitTablet || isMobile) ? '_mobile' : ''}.png`
    return useMemo(() => (
        <Section className='main our_employees team_section_wrapper'>
            <SectionContent className='section_content_title' headline={ourEmployees.headline} text={ourEmployees.text}/>
            <Image className='section_image section_content_image' image={image}/>
        </Section>
    ), [image, ourEmployees]);
}