import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import React, { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import Image from 'components/Elements/Image';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import { selectApp } from 'features/App/app.slice';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './Wallet.scss';

const Wallet = () => {
  return (
    <>
      <HeaderWallet />
      <BlogCTA/>
    </>
  )
}

export default Wallet;

const HeaderWallet = () => {
  const { header } = config.cotiPay
  const { windowDimensions: { isMobile } } = useAppSelector(selectApp);
  const renderHeadline = useMemo(() => <>{header.headline[0]}<span>{header.headline[1]}</span><br/>{header.headline[2]}</>, [header]);
  const imgName = useMemo(() => `${header.image}${isMobile ? '_mobile' : ''}.svg`, [header, isMobile]);

  return useMemo(() => (
    <Section className='main header_wallet'>
      <SectionContent className='content_inner_page' headline={renderHeadline} text={header.text}>
        <Image className='coti_viper_logo' image={`${header.logo}.svg`} />
        <SectionActions>
          <Button
            text={header.buttons.wallet.text}
            gaEvent
            onClick={() => openInNewTab(`${process.env.REACT_APP_WALLET}`)}
          />
        </SectionActions>
      </SectionContent>
      <Image className='section_image' image={imgName} />
    </Section>
  ), [header, renderHeadline, imgName]);
}
