import React, { useMemo } from 'react';
import './MenuButton.scss';

const MenuButton = ({ isMenuOpen, setIsMenuOpen }: any) => {
    return useMemo(() => (
        <div onClick={() => setIsMenuOpen(!isMenuOpen)} className={`menu_button ${isMenuOpen ? 'menu_open' : ''}`}>
          <span/><span/><span/>
        </div>
      ), [isMenuOpen, setIsMenuOpen])
  }

export default MenuButton;