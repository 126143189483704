import React, { useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config, routes, socialNetworks } from 'config/config';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import Image from 'components/Elements/Image';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './MultiDag.scss';

const MultiDag = () => (
  <>
    <MultiDagHeader />
    <BlogCTA />
  </>
)

export default MultiDag;

const MultiDagHeader = () => {
  const { header } = config.multidag
  const renderChildren = useMemo(() => {
    const children = (
      <>
        {header.text.map((p, idx) => {
          return <p key={idx}>{p}</p>
        })}
        <SectionActions>
          <Button
            className='multidag_btn'
            text={header.buttons.multidag.text}
            gaEvent
            onClick={() => openInNewTab(routes.lightpaper.link ?? '')}
          />
          <Button
            className='button secondary_btn'
            icon={socialNetworks.github.icon ? <Image image={socialNetworks.github.icon} /> : ''}
            text={socialNetworks.github.label}
            gaEvent
            onClick={() => openInNewTab(socialNetworks.github.link ?? '')}
          />
        </SectionActions>
      </>
    )
    return children;
  }, [header]);

  return useMemo(() => (
    <Section className='main header_multidag'>
      <SectionContent className='content_inner_page' headline={header.headline} children={renderChildren} />
    </Section>
  ), [header, renderChildren]);
}
