import React, { lazy, Suspense, useMemo } from 'react';
import Image from 'components/Elements/Image';
import Section from 'components/Elements/Section';
import { SectionContent } from 'components/Elements/Section/Section';
import { useAppSelector } from 'redux/hooks';
import { selectApp } from 'features/App/app.slice';
import { config, routes } from 'config/config';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import './Blog.scss';

const BlogCarousel = lazy(() => import('components/Elements/BlogCarousel'));

const Blog = () => {
  return useMemo(() => (
    <div className='blog'>
      <BlogHeader />
      <Suspense fallback={<></>}>
        <BlogCarousel id='grid'/>
        <Button
          text='Blog'
          className='blog_btn'
          onClick={() => openInNewTab(routes.medium.link ?? '')}
        />
      </Suspense>
    </div>
  ), [])
}

export default Blog;

const BlogHeader = () => {
  const { header } = config.blog
  const { windowDimensions: { isMobile, innerHeight, innerWidth } } = useAppSelector(selectApp);
  const isPortrait = useMemo(() => innerHeight > innerWidth, [innerHeight, innerWidth])
  const imgName = useMemo(() => `${header.image}${isMobile && isPortrait ? '_mobile' : ''}.png`, [header, isMobile, isPortrait])
  return useMemo(() => (
    <Section className='blog_header main'>
      <SectionContent headline={header.headline}/>
      <Image image={imgName}/>
    </Section>
  ), [header, imgName])
}