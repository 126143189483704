import React, { useMemo } from 'react';
import { IChildrenType } from 'types/types';
import './Section.scss';

type ISectionTypes = {
  className:string;
  children: IChildrenType;
}

type ISectionContentTypes = {
  headline: JSX.Element[] | JSX.Element | string;
  text?: string;
  children?: IChildrenType;
  className?:string;
}

const Section = ({ className, children}:ISectionTypes) => useMemo(() => (
  <section className={`section_component ${className ?? ''}`}>
    {children}
  </section>
), [children, className])
export default Section;

export const SectionContent = ({ headline, text, children, className }:ISectionContentTypes) => useMemo(() => (
  <div className={`content ${className ?? ''}`}>
    {headline && <h2>{headline}</h2>}
    {text && <p>{text}</p>}
    {children}
  </div>
), [children, headline, text, className])

export const SectionActions = ({children}:{children:IChildrenType}) => useMemo(() => (
  <div className='actions'>
    {children}
  </div>
), [children])