import React, { lazy, Suspense, useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import { useAppSelector } from 'redux/hooks';
import Image from 'components/Elements/Image';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import { selectApp } from 'features/App/app.slice';
import './CotiCoin.scss';

const ExchangesCarousel = lazy(() => import('./ExchangesCarousel'));
const CotiCoinInner = lazy(() => import('./CotiCoinInner'));

const CotiCoin = () => {
  return (
    <>
      <CotiCoinHeader />
      <Suspense fallback={<></>}>
        <div className='cover_carousel_coti_coin'>
          <ExchangesCarousel />
        </div>
        <CotiCoinInner/>
      </Suspense>
      <BlogCTA/>
    </>
  )
}

export default CotiCoin;

const CotiCoinHeader = () => {
  const { header } = config.cotiCoin
  const { windowDimensions: { isMobile } } = useAppSelector(selectApp);
  return useMemo(() => (
    <Section className='main header_coti_coin'>
      <SectionContent className='content_inner_page' headline={<>{header.headline[0]}<br/>{header.headline[1]}</>} text={header.text}>
        <SectionActions>
          <Button
            text={header.buttons.explorer.text}
            gaEvent
            onClick={() => openInNewTab(`${process.env.REACT_APP_EXPLORER}`)} />
          <Button
            text={header.buttons.bridge.text}
            className='secondary_btn'
            gaEvent
            onClick={() => openInNewTab(`${process.env.REACT_APP_BRIDGE}`)} />
        </SectionActions>
      </SectionContent>
      <Image className='section_image' image={`${header.image}${isMobile ? '_mobile' : ''}.png`} />
    </Section>
  ), [isMobile, header]);
}