import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import React, { useMemo } from 'react';
import Image from 'components/Elements/Image';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './CVI.scss';

const CVI = () => {
  return (
    <>
      <HeaderCVI />
      <BlogCTA/>
    </>
  )
}

export default CVI;

const HeaderCVI = () => {
  const { header } = config.cvi

  const renderChildren = useMemo(() => {
    const children = (<>
      {header.text.map((p,idx) => {
        return <p key={idx}>{p}</p>
      })}
      <SectionActions>
        <Button
          text={header.buttons.cvi.text}
          gaEvent
          onClick={() => openInNewTab(`${process.env.REACT_APP_CVI}`)}
        />
        <Button
          text={header.buttons.armadillo.text}
          className='secondary_btn'
          gaEvent
          onClick={() => openInNewTab(`${process.env.REACT_APP_ARMADILLO}`)}
          icon={<Image className='armadillo' image={header.buttons.armadillo.image} />}
        />
      </SectionActions>
    </>)
    return children;
  }, [header]);

  return useMemo(() => (
    <Section className='main header_cvi'>
      <SectionContent className='content_inner_page' headline={header.headline} children={renderChildren} />
    </Section>
  ), [header, renderChildren]);
}
