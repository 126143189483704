import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'hooks';
import { useAppSelector } from 'redux/hooks';
import { selectApp } from 'features/App/app.slice';
import { IEventTypes } from 'types/types';
import './Modal.scss';

export type IModalPosition = {
  clientX: number;
  clientY: number;
}

type IModalTypes = {
    onClose: IEventTypes;
    children: JSX.Element[] | JSX.Element | string;
    modalDetails: IModalPosition;
    center?: boolean;
    className?: string;
}

const ModalLayout = ({ onClose, children, modalDetails, center, className }: IModalTypes) => {
  const [innerModalPosition, setInnerModalPosition] = useState({})
  const {windowDimensions:{innerWidth,innerHeight}} = useAppSelector(selectApp);
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, onClose);
  useEffect(() => {
    if (center) return;
    const setModalPosition = ({height, width} : {height: number, width: number}) => {
      setInnerModalPosition({
        left: innerWidth - modalDetails.clientX < 400 ? (modalDetails.clientX-400 > 0 ? modalDetails.clientX-width : 0) : modalDetails.clientX,
        top: innerHeight - modalDetails.clientY < 400 ? modalDetails.clientY-height : modalDetails.clientY,
      })
    }
    if (containerRef.current) {
      setModalPosition({height: containerRef.current.clientHeight, width: containerRef.current.clientWidth})
    }
  }, [center, containerRef, innerHeight, innerWidth, modalDetails.clientX, modalDetails.clientY]);

  useEffect(()=>{
    document.body.style.paddingRight = "4px"
    document.body.style.overflow = "hidden"
    return () => {document.body.style.overflow = "unset"; document.body.style.paddingRight = "0px";}
  },[]);

  return (
    <div className={`modal_layout ${className ?? ''} ${center ? 'center' : ''}`}>
        <div className="app_icon close_modal" onClick={onClose}/>
        <div className="modal_inner" style={innerModalPosition} ref={containerRef}>
            {children}
        </div>
    </div>
  )
}

export default ModalLayout;