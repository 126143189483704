import api from 'api/api';

export function fetchCotiPrice() {
  return api.getCotiPrice();
}

export function fetchTreasuryStats() {
  return api.getTreasuryStats()
}

export function fetchTreasuryPrograms() {
  return api.getTreasuryPrograms()
}