import { RootState } from 'redux/configure';
import { Dispatch } from 'redux'
import { connected, onApiError, onCotiPriceEvent, onTreasuryTotalsEvent, onTreasuryPrograms } from 'features/App/app.slice'
import { SocketEvents } from 'utilities/types'
import { treasuryApi } from 'api/api'
import { fetchTreasuryPrograms } from 'features/App/appAPI';

interface SocketMiddlewareParams {
  dispatch: Dispatch
  getState: () => RootState
}

export default function socketMiddleware(socket: any) {
  return (params: SocketMiddlewareParams) => (next: any) => (action: any) => {
    const { dispatch } = params
    const { type, payload } = action

    switch (type) {

      // Connect to the socket 
      case 'app/connect': {
        
        socket.connect();
        treasuryApi.interceptors.response.use(response=> response, (error) => {   
            dispatch(onApiError(error));
            return Promise.reject(error);
        });
        
        socket.on('connect', ()=> {
          dispatch(connected());
        });

        socket.on('connect_error', (error: any)=> console.log('socket connection error: ', error));
        break;
      }

      // Disconnect from the socket 
      case 'app/disconnect': {
        console.log('disconnect');
        socket.disconnect()
        break;
      }

      case 'app/cotiPriceEventsSubscription': {
        socket.emit('subscribe', {cotiPrice: true});
        
        socket.on(SocketEvents.CotiPrice, (data: any)=> {
          dispatch(onCotiPriceEvent(data.price));
        })

        break;
      }

      case 'app/treasuryTotalsEventsSubscription': {
        socket.emit('subscribe', { treasuryTotals: true });

        socket.on(SocketEvents.TreasuryTotalsUpdates, async(data: any) => {
          const response = await fetchTreasuryPrograms();
          dispatch(onTreasuryPrograms(response.data))
          dispatch(onTreasuryTotalsEvent(data));
        })
        
        break;
      }
      case 'app/unsubscribe': {
        socket.emit('unsubscribe', payload);
        break;
      }

    }
    return next(action)
  }
}
