import React, { lazy, Suspense, useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config, routes } from 'config/config';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import Image from 'components/Elements/Image';
import { useAppSelector } from 'redux/hooks';
import { selectApp } from 'features/App/app.slice';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './Videos.scss';

const VideosSection = lazy(() => import('./VideosSection'));

const Videos = () => {
  return (
    <>
      <MultiDagHeader />
      <Suspense fallback={<></>}>
        <VideosSection />
      </Suspense>
      <BlogCTA/>
    </>
  )
}

export default Videos;

const MultiDagHeader = () => {
  const { header } = config.videos;
  const {windowDimensions: {isMobile}} = useAppSelector(selectApp);
  const imgName = `${header.image}.svg`

  return useMemo(() => (
    <Section className='main header_videos'>
      <SectionContent className='content_inner_page' headline={header.headline}  >
        <SectionActions>
          <Button
            className='videos_btn'
            text={header.buttons.youtube.text}
            gaEvent
            onClick={() => openInNewTab(routes.youtube.link ?? '')}
          />
        </SectionActions>
      </SectionContent>
      {isMobile ? <Image className='section_image' image={imgName}/> : <></>}
    </Section>
  ), [header, imgName, isMobile]);
}