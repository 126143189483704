import { IPositionDescription } from "features/App/pages/Careers/types";

export const isFoundationWebsite: boolean = process.env.REACT_APP_IS_FOUNDATION === 'true';

export const cotiWebsite: string = process.env.REACT_APP_COTI_IO || 'https://coti.io'

type IRoutes = {
    [key: string]: INavItem;
}

export type INavItem = {
    label: string;
    path?: string;
    link?: string;
    isFoundation?: boolean;
}

type ICategories = {
    [key: string]: INavCategory;
}
export interface INavCategory {
    label: string;
    path?: string;
    routes?: INavItem[];
}

interface ISocialItem extends INavItem {
    icon?: string;
    footer?: boolean;
}

type ISocials = {
    [key: string]: ISocialItem;
}

export type IExchange = {
    label: string;
    logo: string;
    link: string;
}

export const routes: IRoutes = {
  // Home
  home: {
    label: "Home",
    path: "/",
  },
  // Technology
  trustchain: {
    label: "The Trustchain Protocol",
    path: "/trustchain",
  },
  multidag: {
    label: "The COTI MultiDAG",
    path: "/multidag",
  },
  simulation: {
    label: "Watch Simulation",
    path: "/simulation",
  },
  whitepaper: {
    label: "Trustchain White Paper",
    link: `${process.env.PUBLIC_URL}/files/COTI-technical-whitepaper.pdf`,
  },
  lightpaper: {
    label: "MultiDAG Light Paper",
    link: `${process.env.PUBLIC_URL}/files/COTIs-MultiDAG2.0-Protocol-Light-Paper.pdf`,
  },
  treasuryWhitepaper: {
    label: "Treasury White Paper",
    link: `${process.env.PUBLIC_URL}/files/treasury_wp.pdf`,
  },
  github: {
    label: "Github",
    link: "https://github.com/coti-io",
  },
  // Learn
  videos: {
    label: "Videos",
    path: "/videos",
  },
  blog: {
    label: "Blog",
    path: "/blog",
  },
  help: {
    label: "Help Center",
    path: "/help-center",
  },
  helpCenter: {
    label: "Help Center",
    link: "",
  },
  // Ecosystem
  treasury: {
    label: "The COTI Treasury",
    path: "/treasury",
  },
  wallet: {
    label: "COTI Pay VIPER Wallet",
    path: "/wallet",
  },
  business: {
    label: "COTI Pay Business",
    path: "/business",
  },
  adapay: {
    label: "ADA Pay",
    path: "/ada",
  },
  djed: {
    label: "Djed",
    path: "/djed",
  },
  cvi: {
    label: "Crypto Volatility Index (CVI)",
    path: "/cvi",
  },
  // Network
  coin: {
    label: "The COTI Coin",
    path: "/coin",
  },
  explorer: {
    label: "Explorer",
    link: process.env.REACT_APP_EXPLORER,
  },
  bridge: {
    label: "Bridge",
    link: process.env.REACT_APP_BRIDGE,
  },
  // About
  foundation: {
    label: isFoundationWebsite ? "coti.io" : "COTI Foundation",
    link: isFoundationWebsite
      ? process.env.REACT_APP_COTI_IO
      : process.env.REACT_APP_COTI_FOUNDATION,
  },
  team: {
    label: "Team",
    path: "/team",
  },
  community: {
    label: "Community",
    path: "/community",
  },
  careers: {
    label: "Careers",
    path: "/careers",
  },
  // Social
  twitter: {
    label: "Twitter",
    link: "https://twitter.com/COTInetwork",
  },
  instagram: {
    label: "Instagram",
    link: "https://www.instagram.com/cotinetwork/",
  },
  reddit: {
    label: "Reddit",
    link: "https://www.reddit.com/r/cotinetwork/",
  },
  facebook: {
    label: "Facebook",
    link: "https://www.facebook.com/COTInetwork",
  },
  medium: {
    label: "Medium",
    link: "https://medium.com/cotinetwork",
  },
  telegram: {
    label: "Telegram",
    link: "https://t.me/COTInetwork ",
  },
  youtube: {
    label: "Youtube",
    link: "https://www.youtube.com/@COTIGroup",
  },
  linkedin: {
    label: "Linkedin",
    link: "https://www.linkedin.com/company/coti-ltd",
  },
  discord: {
    label: "Discord",
    link: "https://discord.com/invite/wfAQfbc3Df",
  },
  // Terms / Legal
  privacyPolicy: {
    label: "Privacy Policy",
    path: "/privacy",
    link: `${process.env.PUBLIC_URL}/files/coti-privacy_policy_[barnea]_040619.pdf`,
  },
  termsOfService: {
    label: "Terms of Service",
    path: "/terms",
    link: `${process.env.PUBLIC_URL}/files/coti_terms_of_use30July2022.pdf`,
    isFoundation: isFoundationWebsite,
  },
  aml: {
    label: "AML",
    path: "/aml",
    link: `${process.env.PUBLIC_URL}/files/COTI-AML_Policy.pdf`,
    isFoundation: isFoundationWebsite,
  },
  // Other
  android: {
    label: "Android",
    link: "https://play.google.com/store/apps/details?id=com.coti.cotipay",
  },
  ios: {
    label: "Ios",
    link: "https://apps.apple.com/app/coti-pay/id1567021935",
  },
  simplex: {
    label: "Simplex",
    link: "https://account.simplex.com/cards/coti",
  },
  //not found
  notFound: {
    label: "Page not found",
    path: "*",
  },
}

export const categories: ICategories = {
    home: routes.home,
    technology: {
        label: 'V1 Technology',
        routes: [
            routes.trustchain,
            routes.multidag,
            routes.simulation,
            routes.github,
            routes.lightpaper,
            routes.whitepaper,
        ]
    },
    learn: {
        label: 'Learn',
        routes: [
            routes.videos,
            routes.blog,
            routes.help,
        ]
    },
    ecosystem: {
        label: 'Ecosystem',
        routes: [
            routes.treasury,
            routes.wallet,
            routes.business,
            routes.djed,
            routes.adapay,
            routes.cvi
        ]
    },
    network: {
        label: 'Network',
        routes: [
            routes.coin,
            routes.explorer,
            routes.bridge,
        ]
    },
    about: {
        label: 'About',
        routes: [
            routes.team,
            routes.community,
            routes.careers
        ]
    },
    foundation: {
        label: isFoundationWebsite ? 'COTI' : 'Foundation',
        routes: [
            routes.foundation,
        ]
    }
}

export const legal: IRoutes = {
    privacyPolicy: routes.privacyPolicy,
    termsOfService: routes.termsOfService,
    aml: routes.aml
}

export const exchanges: { [key: string]: IExchange } = {
    binance: {
        label: 'Binance',
        logo: 'coin/logos/binance.png',
        link: 'https://accounts.binance.com/en/register?ref=35662651'
    },
    coinbase: {
        label: 'Coinbase',
        logo: 'coin/logos/coinbase.png',
        link: 'https://www.coinbase.com/price/coti'
    },
    kraken: {
        label: 'Kraken',
        logo: 'coin/logos/kraken.png',
        link: 'https://www.kraken.com/prices/coti-price-chart'
    },
    kucoin: {
        label: 'Kucoin',
        logo: 'coin/logos/kucoin.png',
        link: 'https://www.kucoin.com/trade/COTI-USDT'
    },
    bithumb: {
        label: 'Bithumb',
        logo: 'coin/logos/bithumb.png',
        link: 'https://en.bithumb.com/trade/order/COTI_KRW'
    },
    gate: {
        label: 'Gate',
        logo: 'coin/logos/gate.png',
        link: 'https://www.gate.io/trade/COTI_USDT'
    },
    huobi: {
        label: 'Huobi',
        logo: 'coin/logos/huobi.png',
        link: 'https://www.huobi.com/en-us/exchange/coti_usdt'
    },
}

export const positions: IPositionDescription[] = [
    {
        title: 'Lead Product Manager',
        link: 'https://www.linkedin.com/jobs/view/3806667684',
        description: [
            "We're looking for an experienced Lead Product Manager to join us.",
            "The ideal candidate will have a strong understanding of blockchain and decentralized technologies and be responsible for driving the development of our products and services that cater to the Web3 ecosystem. As a Lead Product Manager at COTI you'll work closely with the development team to ensure that our products are aligned with the needs of our customers and the broader industry."
        ],
        jobRequirements: [
            {
                title: 'What you will do:',
                text: [
                    "Maintain the product roadmap for our products and services.",
                    "Collaborate with the product management team to translate business requirements into technical specifications.",
                    "Prioritize features based on customer needs, industry trends, and business objectives.",
                    "Work with cross-functional teams to ensure successful product launches and updates.",
                    "Monitor product performance and customer feedback and make data-driven decisions to optimize product features and usability.",
                    "Act as the main point of contact for stakeholders, providing regular updates on product development and timelines.",
                    "Communicate product vision and strategy to internal teams and external partners."
                ],
            },
            {
                title: 'Requirements:',
                text: [
                    "At least 3 years in a Product Management role (Must)",
                    "Experience with agile development methodologies (Must)",
                    "Excellent communication and collaboration skills, with the ability to work effectively with cross-functional teams (Must)",
                    "Strong analytical and problem-solving skills, with the ability to make data-driven decisions (Must)",
                    "Working proficiency in the English (Must)",
                    "Bachelor's degree in Computer Science, or a related field (Advantage)",
                    "Strong understanding of blockchain and decentralized technologies (Advantage)",
                    "Familiarities with the broader Web3 ecosystem and related technologies, such as smart contracts, decentralized finance (DeFi), and non-fungible tokens (Advantage)"
                ]
            }
        ]
    },
    {
        title: 'Developer Relations Manager',
        link: 'https://www.linkedin.com/jobs/view/3827498288',
        description: [
            "We're looking for an experienced Developer Relations Manager to join our team.",
            "As a Developer Relations Manager, you will help to grow the COTI developer community.",
            "In this role, you will directly engage with developers, creating compelling and authentic resources such as demos, code samples, documentation, how-to guides, and blog posts.",
            "The ideal candidate will play a pivotal role in driving developer adoption and engagement by creating compelling content, delivering impactful presentations, and building strong relationships within the developer community. You will collaborate with cross-functional teams to shape product strategy, represent developer perspectives, and contribute to the success of our developer-focused initiatives."
        ],
        jobRequirements: [
            {
                title: 'What you will do:',
                text: [
                    "Leading the business to the developer marketing process, crafting strategies to effectively reach and engage developer audiences in the COTI ecosystem.",
                    "Collaborating closely with the COTI R&D department to ensure alignment between developer needs and product.",
                    "Developing marketing and growth plans specifically for developers, focusing on attracting, and retaining developer talent.",
                    "Producing and disseminating content tailored to developer communities, aiming to increase both the size and engagement of the developer base.",
                    "Actively participate in the broader blockchain ecosystem and community, establishing and nurturing strong relationships with engineers and developers.",
                    "Lead hackathon strategy and coordination, including sponsorship strategy and connecting with event leadership.",
                    "Relay feedback from the developer community back to the COTI product team, aiding in the continuous improvement of the product based on developer needs and preferences.",
                    "Define and own metrics to measure the success of our developer content and outreach efforts."
                ]
            },
            {
                title: 'Requirements:',
                text: [
                    "Proven experience in developer relations, developer education, and technical writing or related field.",
                    "Ability to build and maintain strong community relationships and engage and connect with developers effectively.",
                    "Proven experience in creating engaging technical content, such as blog posts, tutorials, and videos.",
                    "Ability to engage with developer communities through compelling content creation, including demos, code samples, and documentation.",
                    "Experience of working within cross-functional teams (developer, product, marketing, support, etc).",
                    "Deep technical expertise in crypto or blockchain infrastructure.",
                    "High communication skills including exceptional public speaking and presentation skills, such as meetups and events.",
                    "Creativity, initiative, and the ability to work in a fast-paced environment.",
                    "Team player with a knack for problem-solving."
                ]
            }
        ]
    },
    {
        title: 'Senior Software Engineer',
        link: 'https://www.linkedin.com/jobs/view/3736079947',
        description: [
            "As a Backend Developer at COTI, you'll be responsible for implementing the company core features, building infrastructure, and finding innovative solutions while working with cutting-edge technologies.",
            "We're looking for top talent that's ready to level up their skills in an exciting and fast-paced environment."
        ],
        jobRequirements: [
            {
                title: 'What you will do:',
                text: [
                    "Design, develop, and maintain high-performance, decentralized applications in the crypto world.",
                    "Research and develop new technologies and approaches in the crypto world.",
                    "Use new technologies and cloud-based architecture."
                ]
            },
            {
                title: 'Requirements:',
                text: [
                    "At least +4 Years experience developing in Node.JS (server side)",
                    "Previous experience in a high-tech Industry (preferably startup) \\ financial company",
                    "Good technical skills with a deep understanding of the development lifecycle, methodologies, and best practices",
                    "Ability to execute end-to-end from design to implementation",
                    "Ability to work in a highly dynamic & and technologically driven environment"
                ]
            },
            {
                title: "Nice to have:",
                text: [
                    "Experience in Blockchain\\ fintech companies\\ familiar with the crypto world",
                    "Familiar with MySQL databases",
                    "Experience with AWS",
                ]
            }
        ]
    },
    {
        title: 'Marketing Manager',
        link: 'https://www.linkedin.com/jobs/view/3690360299/',
        description: [
            "COTI Group is a group of companies in the crypto, Defi, Web3, and payments space. We are looking to add an experienced, passionate, marketing professional to join us. As our Marketing Manager, you will plan, manage, and execute our comprehensive marketing strategy, acting as the driving force behind our communications across all platforms."
        ],
        jobRequirements: [
            {
                title: 'Responsibilities:',
                text: [
                    "Design and implement wide-ranging marketing strategies.",
                    "Engage in active collaboration and co-marketing efforts with other industry-leading companies to enhance brand visibility and market reach.",
                    "Manage and maintain our strong social media presence across platforms, including Discord, Telegram, and Twitter.",
                    "Lead community management initiatives to cultivate and engage our users.",
                    "Produce and refine compelling content, ensuring consistent messaging across our channels.",
                ]
            },
            {
                title: 'Must have:',
                text: [
                    "A minimum of 3 years of solid experience in the marketing domain - must",
                    "Previous experience with companies in the Blockchain and Crypto field - must",
                    "Native-level English proficiency, both written and verbal - must",
                    "Passionate about working with proficiency in social media management, particularly on platforms like Twitter, Discord, and Telegram ",
                    "Ability to adeptly manage multiple projects concurrently under pressure ",
                    "A harmonious blend of independent drive and collaborative teamwork",
                    "Competence in understanding and grasping technical aspects",
                ]
            }
        ]
    }
]

export const socialNetworks: ISocials = {
    twitter: {
        ...routes.twitter,
        icon: 'logos/social/x.svg',
        footer: true
    },
    discord: {
        ...routes.discord,
        icon: 'logos/social/discord.svg',
        footer: true
    },
    telegram: {
        ...routes.telegram,
        icon: 'logos/social/telegram.svg',
        footer: true
    },
    reddit: {
        ...routes.reddit,
        icon: 'logos/social/reddit.svg',
    },
    github: {
        ...routes.github,
        icon: 'logos/social/github.svg',
        footer: true
    },
    facebook: {
        ...routes.facebook,
        icon: 'logos/social/facebook.svg'
    },
    medium: {
        ...routes.medium,
        icon: 'logos/social/medium.svg'
    },
    youtube: {
        ...routes.youtube,
        icon: 'logos/social/youtube.svg',
        footer: true
    },
    linkedin: {
        ...routes.linkedin,
        icon: 'logos/social/linkedin.svg'
    },
    instagram: {
        ...routes.instagram,
        icon: 'logos/social/instagram.svg'
    }
}

export const config = {
    foundation: {
        headline: "The COTI\n Foundation",
        text: "The COTI Foundation is an independent non-profit organization, dedicated to the advancement and adoption of the COTI network."
    },
    homepage: {
        enterprise: {
            headline: 'Bringing Privacy to Ethereum',
            text: 'COTI V2 is a Privacy Centric Layer 2 on Ethereum, built with a groundbreaking privacy technology that is fast, secure, and can run on any device.',
            buttons: {
                learn: {
                    text: 'Learn more about COTI V2'
                },
                community: {
                    text: 'Join the Community'
                },
                whitePaper: {
                    text: "COTI V2 White Paper"
                }
            }
        },
        technology: {
            headline: 'Unique Technology',
            text: "The COTI protocol is based on a DAG (Directed Acyclic Graph), which allows scalability and instant settlement while remaining secure. It has a multi-address privacy module, and it employs a one-of-a-kind consensus algorithm known as Proof-of-Trust (PoT) that aims to encourage good network behavior, resulting in lower fees and better service quality. COTI is also eco-friendly, since its transactions do not require mining. The COTI MultiDAG is the first-ever DAG structure that allows token issuance on top of DAG technology.",
            image: 'shape_2',
            buttons: {
                whitepaper: {
                    text: 'READ THE WHITEPAPER'
                },
                simulation: {
                    text: 'Watch the simulation'
                }
            }
        },
        regulation: {
            headline: 'Regulation Ready',
            text: 'COTI is regulation-ready, which is a base requirement for enterprises when entering web3. COTI has performed KYC/AML checks to all holders of COTI’s Native coin in the COTI VIPER Wallet since inception and works in a prudent manner, making it ready for the challenges of tomorrow.',
            image: 'shape_1',
        },
        enterpriseGrade: {
            headline: 'Enterprise Grade Product',
            text: 'COTI is an all-in-one company - protocol and commercial development are both in-house. COTI offers a turn-key solution for enterprises which can be white labeled with products such as: custom branded tokens, wallets, website integration, fiat on and off ramps, processing gateways, Debit cards and bank accounts.',
            image: 'shape_3',
        },
        lighter: {
            headline: 'Lighter',
            text: "The design simplicity of garbled circuits results in an encryption that requires up to 250 times lighter computation and storage than other privacy solutions. This means COTI V2 can operate on any device!",
            image: 'shape_1',
        },
        faster: {
            headline: 'Faster',
            text: 'In addition to the speed and scale of an L2, the lower latency of garbled circuit computation means COTI V2 can settle transactions up to ten times faster than existing smart contract privacy solutions.',
            image: 'shape_3',
        },
        private: {
            headline: 'Private',
            text: 'Garbling protocols offer a revolutionary approach to handling transactional privacy for multiple parties while still allowing network computation. The contents of a transaction are encrypted so that only the involved parties can see the full details. ',
            image: 'shape_4',
        },
        useCases: {
            header: {
                image: 'usecases_background'
            },
            enterprises: {
                headline: 'DEXs',
                text: 'Private transactions give users on decentralized finance applications the features they love on CEX, like proprietary trading and protection against slippage losses from MEV exploits.',
                image: 'dexs.png'
            },
            consumer: {
                headline: "Privacy Wallets",
                text: 'Private payments, confidential transaction history and real-world asset tokenization.',
                image: 'privacy_wallets.png'
            },
            cbdc: {
                headline: 'Governance',
                text: "Confidential on-chain governance will help to reduce voter coercion, improve electoral integrity as well as preserve the privacy of DAO members.",
                image: 'governance.png'
            }
        }
    },
    team: {
        ourEmployees: {
            headline: 'COTI’s Leadership',
            text: '',
            image: 'team/team_shape'
        },
        teamMembers: [
            {
                name: 'Shahaf Bar-Geffen',
                title: 'CEO',
                linkedin: 'shahafbg',
                imgName: 'shahaf_bar_geffen.png',
                key: 'Shahaf Bar-Geffen'
            },
            {
                name: 'Dr. Nir Haloani',
                title: 'CTO',
                linkedin: 'nir-haloani-324876',
                imgName: 'nir_haloani.png',
                key: 'Dr. Nir Haloani'
            },
            {
                name: 'Yair Lavi',
                title: 'Chief Strategy Officer',
                linkedin: 'yairlavi',
                imgName: 'yair_lavi.png',
                key: 'Yair Lavi'
            },
            {
                name: 'Efrat Bar Lev',
                title: 'CMO',
                linkedin: 'efrat-bar-lev-967bb965',
                imgName: 'efrat_bar_lev.png',
                key: 'Efrat Bar Lev'
            },
            {
                name: 'Nir Arazi',
                title: 'COO',
                linkedin: 'nir-arazi-b7725117',
                imgName: 'nir_arazi.png',
                key: 'Nir Arazi',
            },
            {
                name: 'Guy Mesika',
                title: 'Head of R&D',
                linkedin: 'guy-mesika-42293089',
                imgName: 'guy_mesika.png',
                key: 'Guy Mesika'
            },
            {
                name: 'Daniel Gruesso',
                title: 'Head of Product',
                linkedin: 'danielgruesso',
                imgName: 'daniel.png',
                key: 'Daniel Gruesso'
            },
            {
                name: 'Joshua Maddox',
                title: 'Chief of Ecosystem and Partnerships',
                linkedin: 'joshuamaddox',
                imgName: 'joshua.png',
                key: 'Joshua Maddox'
            },
            
        ]
    },
    community: {
        header: {
            headline: 'Join the COTI Community',
            text: 'Join our engaged community that consists of more than 300,000 members from all around the world.',
            image: 'community/background'
        }
    },
    careers: {
        header: {
            headline: 'Join COTI’s Winning Team',
            text: 'We are a technical and creative group of innovators with a wide range of expertise across data science, blockchain technology, research, design, community management, and marketing.',
            image: 'careers/background'
        }
    },
    cotiCoin: {
        header: {
            headline: ['The', 'COTI Coin'],
            text: 'The COTI coin is the native token of the COTI ecosystem. It is a DAG-based cryptocurrency and it is used to cover transaction fees and other tasks throughout the ecosystem.',
            image: 'coin/background',
            buttons: {
                explorer: {
                    text: 'EXPLORER'
                },
                bridge: {
                    text: 'BRIDGE'
                }
            },
        },
        getCoin: "Get COTI"
    },
    banking: {
        header: {
            headline: 'Banking & Debit Cards',
            text: 'COTI offers its users a crypto-friendly bank account to hold fiat and globally spend their balance at any merchant that accepts VISA cards, both online or in-store, using a Debit Card.',
            image: 'banking/card',
            buttons: {
                banking: {
                    text: 'Get started',
                    link: routes.simplex.link
                }
            }
        }
    },
    djed: {
        header: {
            headline: ['Djed', 'Stablecoin'],
            text: [
                "Cardano’s native overcollateralized stablecoin that is developed by IOG and powered by COTI.",
                "DJED is backed by ADA and uses SHEN as a reserve coin."
            ],
            image: 'djed/background',
            buttons: {
                goto: {
                    text: 'GO TO DJED'
                }
            }
        }
    },
    cotiPay: {
        header: {
            logo: 'coti-pay/coti_viper_logo',
            headline: ['COTI', 'Pay', 'VIPER Wallet'],
            text: 'Securely store your COTI using our COTI Pay VIPER wallet',
            image: 'coti-pay/wallet_background',
            buttons: {
                wallet: {
                    text: 'OPEN A COTI VIPER WALLET'
                },
                android: {
                    image: 'coti-pay/android-store.png'
                },
                ios: {
                    image: 'coti-pay/ios-store.png'
                }
            }
        }
    },
    business: {
        header: {
            headline: ['COTI', 'Pay', 'Business'],
            text: 'Through COTI Pay Business merchants are able to receive and process transactions in real-time through our secure whitelabel solutions. Given the secure and innovative infrastructure of our Trustchain, MultiDAG will enable merchants and businesses of all sizes to take advantage of our technology at limited costs.',
            image: 'business/background'
        }
    },
    cvi: {
        header: {
            headline: 'CVI Finance',
            text: [
                "The CVI is a decentralized platform that is like VIX for crypto. Through the CVI index, users are able to gauge current and future volatility expectations and then place trades based on their perceived sentiment, using the CVI permissionless platform.",
                "CVI’s impermanent loss protection, named Armadillo, enables liquidity providers to offset their impermanent loss, solving one of the biggest problems in Defi."
            ],
            buttons: {
                cvi: {
                    text: 'CVI FINANCE'
                },
                armadillo: {
                    text: 'Armadillo',
                    image: 'logos/armadillo.svg'
                }
            }
        },

    },
    trustchain: {
        header: {
            headline: 'The Trustchain Protocol',
            text: [
                "The COTI protocol is based on a Directed Acyclic Graph (DAG) which offer scalable and near-instant settlements while maintaining security. It features a multi-address privacy module and a unique consensus algorithm, Proof-of-Trust (PoT), which incentivizes good network behavior which results in lower fees and improved service quality. Transactions are validated and confirmed quickly through the Trustchain algorithm, which grows as new transactions are linked to previous transactions with similar degrees of trust. This creates an innovative consensus-based confirmation mechanism that rewards users who engage in trust-building behaviors with faster confirmation times.",
                "In order to become a part of COTI’s ledger, transactions must be linked to two previous transactions with a similar Trust Score. Unlike traditional Proof-of-Work (PoW) projects that process transactions linearly, COTI's system connects transactions simultaneously and asynchronously, eliminating scalability issues. Furthermore, COTI is environmentally friendly as it does not require mining and instead assigns network users a Trust Score, making the network energy efficient and sustainable."
            ],
            image: 'trustchain/trustchain_mobile',
            buttons: {
                trustchain: {
                    text: 'Trustchain technical whitepaper'
                },
                github: {
                    text: 'github'
                }
            }
        },
        paymentRequest: {
            headline: 'The Payment Request ',
            text: 'COTI developed a unique feature for enterprises and their users: The Payment Request. This functionality allows the enterprise to cover the gas costs of a transaction, which means that users looking to transact with a merchant or an enterprise don’t need to hold any token prior to submitting their first transaction, contrary to how other networks work, COTI’s technology does not require education or complicated processes that hinder adoption. COTI is the only Layer 1 that makes it possible for enterprises to pay those fees on behalf of the users.',
        }
    },
    multidag: {
        header: {
            headline: 'The COTI MultiDAG',
            text: [
                'The MultiDAG is the first-ever DAG structure that allows token issuance on top of DAG technology. This achievement was made possible by combining independent DAG clusters into the overall COTI network. The COTI MultiDAG ecosystem is similar to Ethereum, in the sense that both infrastructures act as the basis for a multitude of different tokens while utilizing one main coin for all fees.',
                'Token issuers on other Layer 1s are limited by the overall rules and performance of the network as a whole, while on the COTI MultiDAG token issuers retain control of their own rules and instances are not limited by the overall performance of the network. This allows for almost limitless instances and tokens to exist without any network degradation. MultiDAG instances retain the ability to access all the overall COTI network features such as high scalability, low transaction costs and privacy.'
            ],
            buttons: {
                multidag: {
                    text: 'GO TO LIGHTPAPER'
                }
            }
        }
    },
    videos: {
        header: {
            headline: 'COTI’s Videos',
            buttons: {
                youtube: {
                    text: 'GO TO YOUTUBE CHANNEL'
                }
            },
            image: 'videos/video_play_symbol'
        },
        videos: [
            {
                headline: 'How COTI works',
                youtubeId: 'CXo7i_gdNR0'
            },
            {
                headline: 'COTI’s Next-Gen Financial Ecosystem',
                youtubeId: 'uitUa8krJJk'
            },
            {
                headline: 'Introducing COTI V2 - A Privacy-Centric Ethereum L2',
                youtubeId: 'Ut-B8O6wXLw'
            }
        ]
    },
    blog: {
        header: {
            headline: "Welcome To COTI's Blog",
            image: 'blog/background'
        }
    },
    ada: {
        header: {
            headline: 'ADA Pay',
            text: 'ADA Pay is an ADA payment system for businesses that is built on the Cardano blockchain. The offering allows merchants and charities to accept ADA as a payment method with instant settlement.',
            image: 'ada/adapay_logo',
            buttons: {
                ada: {
                    text: 'Explore ADA Pay'
                }
            }
        }
    },
    treasury: {
        header: {
            headline: 'Treasury',
            image: 'treasury/treasury_chest',
            text: [
                "COTI’s Treasury is a pool of $COTI where users can deposit any amount of $COTI and earn rewards for their participation. By choosing the amount of $COTI to deposit, a multiplier, and a lock period, users can customize their risk levels and potential APY.",
                "All fees collected by the COTI ecosystem and the Treasury are funneled into the Treasury and distributed as rewards to its users."
            ],
            buttons: {
                treasuryApp: {
                    text: 'Get Started'
                },
                whitepaper: {
                    text: 'Treasury Whitepaper'
                }
            },
            video: {
                howTreasuryWorks: {
                    headline: 'How The Treasury Works',
                    youtubeId: 'uitUa8krJJk',
                },
                tutorial: {
                    headline: "COTI's Treasury Tutorial",
                    youtubeId: 'QXayGW--jY8',
                }
            }
        }
    },
    simulation: {
        header: {
            headline: "COTI's Simulation",
            text: '',
            image: 'simulation/background',
            buttons: {
                simulation: {
                    text: 'WATCH EXPLAINER VIDEO'
                }
            }
        },
        Simulation: {
            headline: 'Simulation 3D',
            text: [
                'The Cluster, COTI’s distributed ledger, is based on a directed acyclic graph (DAG) data structure, which is simulated below.',
                'The flow of new transactions has been modeled using the Poisson process. Each new transaction utilises the Source Selection algorithm to select two sources, or points that have no inbound transactions in the DAG. It then validates those sources and becomes a source itself.',
                'To reach transaction consensus, we need to find the heaviest cumulative Trust Score chain, or the Trustchain as we call it. If the cumulative Trust Score of the Trustchain has surpassed a predetermined threshold, then the transaction will have reached Trustchain consensus and can be confirmed.'
            ],
            buttons: {
                algorithm: {
                    text: 'COTI algorithm SIMULATION EXPLAINED',
                    link: 'https://medium.com/cotinetwork/cotis-algorithm-simulation-explained-3519f778c1b8'
                }
            }
        },
        filteSimulation: {
            text: [
                'Change simulation parameters in the dropdown menus.',
                'The simulation parameters are as follows:'
            ]
        },
        graph: {
            headline: 'COTI’s Cluster',
            text: 'Note: Click transaction to focus your view on each transaction. Click on confirmed transactions to highlight confirming paths in red',
            map: ['Source', 'Validate', 'Confirmed']
        }
    },
    help: {
        header: {
            headline: 'COTI’s Help Center',
            image: 'help/help_chat_bubbles',
        }
    },
    CTA: {
        blog: {
            title: 'COTI’s Blog',
            headline: 'Read our latest articles on COTI’s Blog',
            buttonText: 'GO TO COTI’S BLOG',
            path: routes.medium.link,
        }
    },
    notFound: {
        header: {
            headline: "404",
            text: [
                "Page not found.",
                "The page that you are looking for does not exist."
            ],
            image: "404",
            buttons: {
                home: {
                    text: "GO TO HOMEPAGE"
                }
            }
        }
    },
    footer: {
        contactEmail: isFoundationWebsite ? 'contact@coti.foundation' : 'contact@coti.io'
    }
}

export const bannerConfig = {
    airDrop: {
        text: "COTI V2 Airdrop Campaign is LIVE!",
        link: "https://medium.com/cotinetwork/coti-v2-airdrop-campaign-all-the-details-cdb1cdeacca3",
        showInPath: ["/", "/treasury"],
    },
}