import React, { lazy, Suspense, useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import { useAppSelector } from 'redux/hooks';
import Image from 'components/Elements/Image';
import { selectApp } from 'features/App/app.slice';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './Careers.scss';

const OpenPositions = lazy(() => import('./OpenPositions'));

const Careers = () => {
  return (
    <>
      <HeaderCareers />
      <Suspense fallback={<></>}>
        <OpenPositions/>
      </Suspense>
      <BlogCTA/>
    </>
  )
}

export default Careers;

const HeaderCareers = () => {
  const { header } = config.careers
  const { windowDimensions: { isMobile } } = useAppSelector(selectApp);
  return useMemo(() => (
    <Section className='main header_careers'>
      <SectionContent className='content_inner_page' headline={header.headline} text={header.text} />
      <Image className='section_image' image={`${header.image}${isMobile ? '_mobile' : ''}.png`} />
    </Section>
  ), [isMobile, header]);
}