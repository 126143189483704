import React, { useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import Image from "components/Elements/Image";
import {useAppSelector} from "../../../../redux/hooks";
import {selectApp} from "../../app.slice";


const FoundationHeader = () => {
    const { headline, text } = config.foundation
    const { windowDimensions: { isMobile } } = useAppSelector(selectApp);
    const imgName = useMemo(() => `foundation/foundation-imgs${isMobile ? '_mobile' : ''}.png`, [isMobile]);
    return useMemo(() => (
        <Section className='main header_coti_foundation'>
            <SectionContent headline={headline} text={text} />
            <Image className='section_image' image={imgName}/>
        </Section>
    ), [headline, imgName, text]);
}

export default FoundationHeader;