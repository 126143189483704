import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config, routes } from 'config/config';
import Value from 'components/Elements/Value';
import { commaFormatted, openInNewTab, removeCommas } from 'utilities/utils';
import Button from 'components/Elements/Button';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getTreasuryPrograms, getTreasuryStats, selectApp, treasuryTotalsEventsSubscription, unsubscribe } from 'features/App/app.slice';
import Image from 'components/Elements/Image';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './Treasury.scss';
import AnimatedValue from 'components/Elements/AnimatedValue';
import BigDecimal from 'decimal.js-light'

const TreasuryVideo = lazy(() => import('./TreasuryVideo'));

const Treasury = () => {
  return useMemo(() => (
    <>
      <div className='treasury'>
        <TreasuryHeader />
        <Suspense fallback={<></>}>
          <TreasuryVideo />
        </Suspense>
      </div>
      <BlogCTA />
    </>
  ), [])
}

export default Treasury;

const TreasuryHeader = () => {
  const dispatch = useAppDispatch();
  const { connected, treasury: { totalCotiInPool, maxTotalApy, tvl, program }, windowDimensions: { isMobile } } = useAppSelector(selectApp);
  const { headline, text, buttons, image } = config.treasury.header
  const imgName = `${image}.svg`

  const totalCOTI: string = useMemo(() => {
    const totalNative = removeCommas(totalCotiInPool || "0");
    const totalErc = removeCommas(program?.depositedAmount.find(item => item.assetSymbol === "COTI")?.valueInCoti || "0");
    return new BigDecimal(totalNative).add(new BigDecimal(totalErc)).toString()
  }, [program?.depositedAmount, totalCotiInPool]);

  const totalUSD: string = useMemo(() => {
    const totalUsd = removeCommas(tvl || "0");
    const totalErcUsd = removeCommas(program?.totalUsdValue || "0")
    return new BigDecimal(totalUsd).add(new BigDecimal(totalErcUsd)).toString()
  }, [program?.totalUsdValue, tvl]);

  useEffect(() => {
    dispatch(getTreasuryStats());
    dispatch(getTreasuryPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connected) {
      dispatch(treasuryTotalsEventsSubscription());
    }

    return () => {
      dispatch(unsubscribe({ treasuryTotals: true }));
    }
  }, [connected, dispatch]);

  return useMemo(() => (
    <Section className='main treasury_stats'>
      <SectionContent headline={headline}>
        <>{text.map((p, idx) => <p key={idx} className={`${idx}`}>{p}</p>)}</>

        {totalCOTI ? <AnimatedValue
          className='tvl'
          suffix='COTI'
          value={commaFormatted(totalCOTI, 0)}
        /> : <></>}

           {totalUSD ? <AnimatedValue
          className='tvl_usd'
          prefix='$'
          value={commaFormatted(totalUSD, 0)}
        /> : <></>}

        <Value
          className='max_apy'
          prefix='Max APY'
          value={maxTotalApy ? commaFormatted(maxTotalApy, 0) : ''}
          suffix='%'
        />
        
        <SectionActions>
          <Button
            className='treasury_app_btn'
            text={buttons.treasuryApp.text}
            gaEvent
            onClick={() => openInNewTab(`${process.env.REACT_APP_TREASURY}`)}
          />
          <Button
            className='button secondary_btn'
            text={buttons.whitepaper.text}
            gaEvent
            onClick={() => openInNewTab(routes.treasuryWhitepaper.link ?? '')}
          />
        </SectionActions>
      </SectionContent>
      {isMobile ? <Image className='section_image' image={imgName} /> : <></>}
    </Section>
  ), [headline, text, totalCOTI, totalUSD, maxTotalApy, buttons.treasuryApp.text, buttons.whitepaper.text, isMobile, imgName])
}