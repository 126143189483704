export const openInNewTab = (url:string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
}

const removeZerosFromEndOfNumber = (number:string) => {
    if (number.includes('.')) {
        while (number.charAt(number.length - 1) === "0") {
            number = number.substring(0, number.length - 1);
        }
        if (number.charAt(number.length - 1) === ".")
            number = number.substring(0, number.length - 1);
    }
    return number;
}

export const commaFormatted = (amount:string | number, fixed?: number) => {
    if(typeof amount === "string" && !amount.includes(".")) return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    amount = removeZerosFromEndOfNumber(amount.toString());
    var parts = amount.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(typeof fixed === "number") {
        parts[1] = parts[1]?.slice(0,fixed);
    }
    return parts[1] ? removeZerosFromEndOfNumber(parts.join(".")) : parts[0];
}

export const removeCommas = (value: string): string => value.replace(/,/g, "");
