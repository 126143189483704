import React, { lazy, Suspense } from "react"
import Button from "components/Elements/Button"
import { routes } from "config/config"
import HomepageHeader from "./HomepageHeader"
import { openInNewTab } from "utilities/utils"
import "./Homepage.scss"

const Roadmap = lazy(() => import("./Roadmap"))
const Homeintro = lazy(() => import("./Homeintro"))
const Headline = lazy(() => import("./Headline"))
const Lighter = lazy(() => import("./Lighter"))
const Faster = lazy(() => import("./Faster"))
const Private = lazy(() => import("./Private"))
const UseCases = lazy(() => import("./UseCases"))
const BlogCarousel = lazy(() => import("components/Elements/BlogCarousel"))

const Homepage = () => {
  return (
    <div className="homepage">
      <HomepageHeader />
      <Homeintro />
      <Suspense fallback={<></>}>
        <Headline />
        <Lighter />
        <Faster />
        <Private />
        <UseCases />
        <Roadmap />
        <BlogCarousel />
        <Button
          text="Blog"
          className="blog_btn"
          onClick={() => openInNewTab(routes.medium.link ?? "")}
        />
      </Suspense>
    </div>
  )
}

export default Homepage
