import React, { lazy, Suspense, useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import { useAppSelector } from 'redux/hooks';
import Image from 'components/Elements/Image';
import { selectApp } from 'features/App/app.slice';
import './HelpCenter.scss';

const HelpCenterInner = lazy(() => import('./HelpCenterInner'));

const HelpCenter = () => {
  return (
    <>
      <HelpCenterHeader />
      <Suspense fallback={<></>}>
        <HelpCenterInner/>
      </Suspense>
    </>
  )
}

export default HelpCenter;

const HelpCenterHeader = () => {
  const { header } = config.help;
  const {windowDimensions: {isMobile}} = useAppSelector(selectApp);
  const imgName = `${header.image}.png`

  return useMemo(() => (
    <Section className='main header_help'>
      <SectionContent className='content_inner_page' headline={header.headline} />
      {isMobile ? <Image className='section_image' image={imgName}/> : <></>}
    </Section>
  ), [header, isMobile, imgName]);
}