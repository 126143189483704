import React from "react";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <div id="privacy" className="c18 doc-content">
      <p className="s99">PRIVACY POLICY</p>
      <br />
      <ol id="l1">
        <li data-list-text="1.">
          <h1>General</h1>
          <ol id="l2">
            <li data-list-text="1.1.">
              <p>
                COTI (BVI) Limited(the “<b>Company</b>”, “<b>we,</b>” or “<b>our,</b>”) of Trinity Chambers, PO BOX 4301, Road Town, Tortola, British Virgin Islands are fully committed to protecting your privacy  and  your  information  (personal-identifying  Information  and  general  non-personal activity log information, being the 
                &quot; <b>"Information"</b>&quot;).
              </p>
            </li>
            <li data-list-text="1.2.">
              <p>
                We have created this Privacy Policy to let you know what
                Information we (or others on our behalf) collect from you when
                you access and/or use our website located at
                <a
                  href="http://www.coti.io/"
                  className="s2"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;www.coti.io&nbsp;
                </a>
                (the “<b>Website</b>”), how this Information is collected and
                how is it used. 
              </p>
            </li>
            <li data-list-text="1.3.">
              <p>
                This Privacy Policy is part of and should be read in conjunction
                with our Terms of Use and Token Purchase Agreement. When you
                access or use the Website, you agree that we (or
                others on our behalf) may collect, use and disclose the
                Information in accordance with the terms of this Privacy Policy.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="2.">
          <h1>Collection of Information</h1>
          <ol id="l3">
            <ol id="l4">
              <li data-list-text="2.1">
                <p>
                  When you visit or use the Website and in your communications
                  with us, we (or others on our behalf, including but not
                  limited to our affiliates) may collect the following types of
                  Information:
                </p>
                <ol id="l5">
                  <li data-list-text="(a)">
                    <p>
                      Personal and contact details: username, password, name,
                      company name, email address;
                    </p>
                  </li>
                  <li data-list-text="(b)">
                    <p>
                      Payment details: billing address and credit card
                      information;
                    </p>
                    <p>
                      (i) Server Log Information which may include (but is not limited to) your login details,
                      the date and time of visits, the pages viewed, your IP address, 
                      time spent on the Website and the websites you visit just before and just after visiting the Website.
                    </p>
                  </li>
                  <li data-list-text="(c)">
                    <p>
                      Device Information about the computer or mobile device you
                      use to access the Website, including the hardware model,
                      operating system and version, the web browser you use, and
                      other device identifiers.
                    </p>
                  </li>
                  <li data-list-text="(d)">
                    <p>
                      Telemetry Information if you use any of our open-source
                      software, including bandwidth upload and download speeds,
                      the amount of free and used storage space on your device
                      and other device statistics.
                    </p>
                  </li>
                  <li data-list-text="(e)">
                    <p>
                      Usage Information, metadata about the files you upload for
                      storage, recordings of instances in which you have used
                      your private key to authenticate communications.
                    </p>
                  </li>
                </ol>
              </li>
              <li data-list-text="2.2">
                <p>
                  When you are paying through and redirected to COTI wallet or when you open or hold
                  a COTI wallet, we (or others on our behalf, including but not limited to our affiliates)
                  may collect the following types of Information:
                </p>
                <ol id="l6">
                  <li data-list-text="(a)">
                    <p>
                    Personal and contact details: email address, and other information provided by you.
                    </p>
                  </li>
                  <li data-list-text="(b)">
                    <p>
                    Identifying documents: proof of identity (POI), proof of address document (such as
                    a bank statement or a utility bill);
                    </p>
                  </li>
                  <li data-list-text="(c)">
                    <p>Your IP address.</p>
                  </li>
                </ol>
              </li>
              <li data-list-text="2.3">
                <p>
                By providing us with your “personally identifiable information”, you also consent for us to collect, hold, use and disclose your personal information in accordance with this Privacy Policy. In addition to providing the foregoing information, if you choose to correspond further with us through e-mail or through the “Contact” section of our Website we may retain, use and disclose the content of your messages together with your e-mail address and our responses.
                </p>
              </li>
            </ol>
          </ol>
        </li>
        <li data-list-text="3.">
          <h1>Data Storage</h1>
          <p>
            3.1 We may use third party vendors and hosting partners to provide
            the necessary hardware, software, networking, storage and related
            technology required to run the Website.
          </p>
          <br />
        </li>
        <li data-list-text="4.">
          <h1>Use of Information</h1>
          <p>
          We may use the Information for purposes of, including but not limited to: (i) providing, maintaining, delivering or improving the Website, products or services provided through the Website; (ii) analyzing and tracking data to determine the usefulness or popularity of certain content and to better understand the online activity of the Website users; (iii) fulfilling our legal or regulatory requirements; (iv) providing you with the information or products or services that you have requested; (v) responding to your inquiries or to other communication received from you; (vi) developing new products or services; (vii) sending and communicating with you about news, products, services, events and other information we think will be of interest to you; (viii) monitoring and analyzing trends, usage and activities in connection with the Website; (ix) detecting, investigating and preventing fraudulent transactions or unauthorized or illegal activities; (x) protecting the rights and property of the Company and/or others; (xi) linking, connecting or combining Information we collect from or about you with other information; and (xii) carrying out any other purpose or reason for which the Information was collected.
          </p>
        </li>
        <li data-list-text="5.">
          <h1>Google AdWords</h1>
          <ol id="l7">
            <ol id="l8">
              <li data-list-text="5.1">
                <p>
                The Website may use the Google AdWords remarketing service to advertise on third party websites (including, but without limiting, Google) to previous visitors to the Website; it could mean that we advertise to previous visitors who haven’t completed a task on the Website. Third party vendors, including Google, may use cookies to serve ads based on someone’s past visits to the Website. You understand that any data collected will be used in accordance with the provisions of the Privacy Policy and Google’s privacy policy, respectively.
                </p>
              </li>
              <li data-list-text="5.2">
                <p>
                  You can set preferences for how Google advertises to you, and
                  if you want to you can opt out of interest-based advertising
                  entirely by cookie settings, permanently using a browser
                  plugin or by any other way available subject to the relevant
                  frameworks you are using in this regard.
                </p>
              </li>
            </ol>
          </ol>
          <br />
        </li>
        <li data-list-text="6.">
          <h1>Sharing of Information</h1>
          <ol id="l9">
            <ol id="l10">
              <li data-list-text="6.1">
                <p>
                  We may share your Information to any third parties, provided
                  that the Information is shared: (i) upon your consent or
                  instruction; (ii) to our current or future parent companies,
                  affiliates, subsidiaries and/or with other companies under
                  common control or ownership with us or our offices
                  internationally; (iii) to third parties or service providers
                  that perform work for us; (iv) in the event that we sell,
                  assign or transfer some or all of our business or assets to a
                  successor or acquirer, or if we are acquired by or merge with
                  a third party, or if we file for bankruptcy or become
                  insolvent, or any other situation where Information may be
                  sold, assigned or transferred to a successor or acquirer; (v)
                  in response to a request for information if we believe
                  disclosure is in accordance with, or required by, any
                  applicable law, regulation or legal process; (vi) if we
                  believe your actions are inconsistent with any of our
                  agreements and/or policies, or to protect the rights, property
                  and safety of any of our parties or others; (vii) in the event
                  that a data center is compromised by a third party and/or is
                  faced with a security breach; and (viii) to third parties
                  where aggregate Information is disclosed which cannot be
                  reasonably used to identify you.
                </p>
              </li>
              <li data-list-text="6.2">
                <p>
                We may disclose the Information if required to do so by law, or if we believe in good faith that such action is necessary to comply with applicable regulation, a current judicial proceeding, a court order or legal process, or to protect and defend our rights or property, your personal safety or that of the public. We reserve the right to share the aforesaid Information with banks, credit card companies, and appropriate institutions, if we determine in our sole discretion that it is required in order to comply with applicable regulation or if we suspect you of committing any fraudulent activity (including of fraudulent payment), or any other prohibited transaction. 
                </p>
              </li>
              <li data-list-text="6.3">
                <p>
                  We may disclose the Information if required to do so by law,
                  or if we believe in good faith that such action is necessary
                  to comply with applicable regulation, a current judicial
                  proceeding, a court order or legal process, or to protect and
                  defend our rights or property, your personal safety or that of
                  the public. We reserve the right to share the aforesaid
                  Information with banks, credit card companies, and appropriate
                  institutions, if we determine in our sole discretion that it
                  is required in order to comply with applicable regulation or
                  if we suspect you of committing any fraudulent activity
                  (including of fraudulent payment), or any other prohibited
                  transaction.
                </p>
              </li>
              <li data-list-text="6.4">
                <p>
                  We may transfer the Information outside the jurisdiction which
                  you are a resident of and store it in other jurisdictions and
                  countries; the data protection and other laws of these
                  jurisdictions or countries may not be as comprehensive as
                  those in the jurisdiction which you are resident – in these
                  instances we will take steps to ensure that an appropriate
                  level of protection is given to your Information.
                </p>
              </li>
              <li data-list-text="6.5">
                <p>
                  Any person who accesses or uses the Website and/or the
                  Services represents to us that he is 18 (eighteen) years of
                  age (or such other lawful age – where higher than 18) or
                  older. It is our policy to try and uncover attempts by minors
                  to access the Website and our Services, and this may require
                  having to access and verify your Information. We will not
                  accept and take actions to remove from our records any
                  Information which we discover is or was submitted by a minor.
                </p>
              </li>
              <li data-list-text="6.6">
                <p>
                  We may share Information with identity-check providers and/or
                  initiate submitting inquiries to data aggregators (such as
                  watch-list checks providers), to maintain that our internal
                  procedures comply with the applicable regulation.
                </p>
              </li>
              <li data-list-text="6.7">
                <p>
                  We will retain the Information only for as long as necessary
                  for the fulfillment of the purposes for which such Information
                  is collected.
                </p>
              </li>
            </ol>
          </ol>
          <br />
        </li>
        <li data-list-text="7.">
          <h1>Social Sharing Features</h1>
          <p>
            The Website may offer social sharing features, links to social
            media and other integrated tools. Your use of such features enables
            the sharing of information with the social network operator, your
            contacts and/or the public, depending on the settings you use with
            the entity that provides the social sharing feature or social media.
            Please visit the privacy policies of the entities that provide these
            features to obtain more information about the purpose and scope of
            data collection and the processing of such data by such entities.
          </p>
          <br />
        </li>
        <li data-list-text="8.">
          <h1>Choice / Opt-Out</h1>
          <ol id="l11">
            <ol id="l12">
              <li data-list-text="8.1">
                <p>
                  By accessing to and using the Website and/or the Services, you
                  hereby allow us to provide you with communications from us, as
                  well as from third parties, either in
                </p>
                <p>
                  connection with the Website and/or the Services, or in
                  connection with other (including, but not limited to,
                  commercial offers and promotions that we think may be of
                  interest) matters and offers. You hereby acknowledge and
                  consent to the sending of such communications, and will not
                  argue that such activity amounts to sending of unsolicited
                  communications. You also hereby acknowledge that we are not
                  responsible for the communication sent to you by third parties
                  or for their content and method of distribution.
                </p>
              </li>
              <li data-list-text="8.2">
                <p className="s3">
                  You may opt-out from receiving communications from us by
                  sending a blank e-mail to us with the word “Remove” to
                  <a
                    href="mailto:contact@coti.io"
                    className="s2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    &nbsp;contact@coti.io&nbsp;
                  </a>
                  <span>
                    and/or by clicking on the unsubscribe link available in our
                    e-mails sent to you.
                  </span>
                </p>
              </li>
            </ol>
          </ol>
          <br />
        </li>
        <li data-list-text="9.">
          <h1>Cookies</h1>
          <ol id="l13">
            <ol id="l14">
              <li data-list-text="9.1">
                <p>
                  We (or others on our behalf) use various technologies to
                  collect information when you interact with our Website,
                  including cookies and web beacons. Cookies, which are small
                  text files that are placed on your computer or equipment when
                  you visit certain online pages, track your activities, record
                  your preferences, and make the Website more responsive to your
                  needs by delivering a better and more personalized experience
                  to you. The cookies are stored on your computer and/or other
                  equipment and are used by us to help track your clicks and
                  pass information as you go through the Website and can allow
                  us to associate navigational information from browsing visits
                  with Information you provide via the Website. Since cookies
                  allow you to more easily navigate web sites, you may prefer to
                  leave them turned on. Web beacons are graphic images that are
                  placed on the Website or in an email that is used to monitor
                  your behavior while visiting the Website or sending the email
                  and are often used in combination with cookies.
                </p>
              </li>
              <li data-list-text="9.2">
                <p>
                  If you are accessing the internet through an alternative
                  method such as a mobile application you understand that you
                  may not have the ability to disable cookies, depending on the
                  specific access method.
                </p>
              </li>
            </ol>
          </ol>
          <br />
        </li>
        <li data-list-text="10.">
          <h1>Security</h1>
          <ol id="l15">
            <li data-list-text="10.1.">
              <p>
                We have implemented suitable security policies, rules and
                technical measures to protect and safeguard the Information
                under our control from unauthorized access, improper use or
                disclosure, unauthorized modification, unlawful destruction or
                accidental loss. All our employees and data processors that have
                access to, and are associated with the processing of your
                Information, are obliged to respect the confidentiality of your
                Information. When your Information is transferred via the
                Website or the Services, we
              </p>
              <p>
                use various means and methods to ensure that your Information is
                transferred securely over the internet.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li data-list-text="11.">
          <h1>Links to Other Sites</h1>
          <ol id="l16">
            <li data-list-text="11.1.">
              <p>
                The Website may contain links to other sites. Other sites may
                also reference or link to the Website. We are not responsible
                for the privacy practices or the content of such other online
                sites, and any information collected by these third party online
                sites is not governed by this Privacy Policy, and we assume no
                responsibility or liability whatsoever for the policies
                (including privacy policies), practices, actions or omissions of
                such third party sites.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li data-list-text="12.">
          <h1>Disclaimer</h1>
          <ol id="l17">
            <li data-list-text="12.1.">
              <p>
              The Website operated “as is” and “as available” without liability of any kind. We are not responsible for events beyond our direct control. We cannot guarantee nor do we represent that there will be error-free performance regarding the privacy of your Information, and we will not be liable for any direct, indirect, incidental, consequential or punitive damages relating to the use or release of the Information.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li data-list-text="13.">
          <h1>Jurisdictions</h1>
          <ol id="l18">
            <li data-list-text="13.1.">
              <p>
                  We make no representation that the Website operates (or is legally permitted to operate) in all geographic areas or that the Website,
                  or information, services or products offered through the Website are appropriate or available for use in other locations. Accessing
                  the Website from territories where the Website or any content or functionality of the Website or portion thereof is illegal is expressly
                  prohibited. If you choose to access the Website, you agree and acknowledge that you do so, on your own initiative and at your own risk and
                  that you are solely responsible for compliance with all applicable laws.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li data-list-text="14.">
          <h1>Changes to the Privacy Policy</h1>
          <ol id="l19">
            <li data-list-text="14.1.">
              <p>
                We may change this Privacy Policy in our sole discretion at any
                time. Any revisions to this Privacy Policy will be posted as an
                updated version accessible via the Website home page. We
                recommend that you revisit this Privacy Policy regularly so as
                to be kept apprised of any such changes. Your continued use of
                the Website, the Services, or any other features, technologies
                or functionalities offered by us constitutes your agreement to
                be bound by any such changes to this Privacy Policy. If you do
                not agree with this Privacy Policy do not use the Website or the
                Services.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="15.">
          <h1>Miscellaneous</h1>
          <ol id="l20">
            <li data-list-text="15.1.">
              <p>
                For any queries relating to this Privacy Policy and data
                protection please contact us at (contact@coti.io) or via the
                communication details appearing in the footer section of the
                Website.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>
        <br />
      </p>
    </div>
  );
};

export default Privacy;
