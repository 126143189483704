import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router';
import { routes } from 'config/config';
import Section from 'components/Elements/Section';
import AML from './docs/AML';
import Privacy from './docs/Privacy';
import Terms from './docs/Terms';
import './Legal.scss';
import TermsFoundation from "./docs/TermsFoundation";
import AmlFoundation from './docs/AmlFoundation';

const Legal = () => {
    const location = useLocation();
    const path = location.pathname;
    const renderDocument = useCallback(() => {
        switch (path) {
            case routes.aml.path:
                return process.env.REACT_APP_IS_FOUNDATION  ? <AmlFoundation/> : <AML/>  
            case routes.privacyPolicy.path:
                return <Privacy/>
            case routes.termsOfService.path:
            default:
                return process.env.REACT_APP_IS_FOUNDATION  ? <TermsFoundation/> : <Terms/>
        }
    }, [path])
    return useMemo(() => (
        <Section className='legal flex'>
            {renderDocument()}
        </Section>
    ), [renderDocument])
}

export default Legal;