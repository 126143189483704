import { isFoundationWebsite, routes } from "config/config"
import React, { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { IChildrenType } from "types/types"
import "./PageLayout.scss"

type PageLayoutTypes = {
  children: IChildrenType
  className?: string
}

const PageLayout: React.FC<PageLayoutTypes> = (props) => {
  const location = useLocation()
  const path = location.pathname

  const bgPageImg = useMemo(() => {
    if (isFoundationWebsite) return "background_foundation"
    switch (path) {
      case routes.multidag.path:
        return "background_multidag"
      case routes.adapay.path:
        return "background_ada"
      case routes.treasury.path:
        return "background_treasury"
      case routes.help.path:
        return "background_help"
      case routes.simulation.path:
        return "background_simulation"
      case routes.videos.path:
        return "background_video"
      case routes.trustchain.path:
        return "background_trustchain"
      case routes.cvi.path:
        return "background_cvi"
      case routes.foundation.path:
        return "background_foundation"
      default:
        return ""
    }
  }, [path])

  const bgImg = useMemo(() => {
    if (isFoundationWebsite) return "background"
    switch (path) {
      case routes.djed.path:
        return "background_djed"
      case routes.foundation.path:
        return "background"
      default:
        return "background_regular"
    }
  }, [path])

  return useMemo(
    () =>
      props.children ? (
        <div className={`page_layout ${props.className ?? ""}`}>
          {bgImg && <div className={`background ${bgImg}`} />}
          <div className={`background ${bgPageImg}`} />
          {props.children}
        </div>
      ) : null,
    [props, bgImg, bgPageImg]
  )
}

export default PageLayout
