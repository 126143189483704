import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/configure';
import { IAppState } from 'utilities/types';
import { fetchCotiPrice, fetchTreasuryPrograms, fetchTreasuryStats } from './appAPI';

export const initialState: IAppState = {
  treasury: {
    totalCotiInPool: null,
    tvl: null,
    maxTotalApy: null,
    cotiPrice: null,
    program: null,
  },
  windowDimensions: {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    isMobile: window.innerWidth < 768,
    isPortraitTablet: window.innerWidth >= 768 && window.innerWidth < 1024,
    isTablet: window.innerWidth >= 1024 && window.innerWidth < 1369,
    isDesktop: window.innerWidth >= 1369,
    isPortrait: window.innerWidth < window.innerHeight
  },
  connected: false,
  apiError: null
};

export const getCotiPrice = createAsyncThunk(
  '/coti-price',
  async () => {
    const response = await fetchCotiPrice();
    return response.data;
  }
);

export const getTreasuryStats = createAsyncThunk(
  '/treasuryStats',
  async () => {
    const response = await fetchTreasuryStats();
    return response.data;
  }
);

export const getTreasuryPrograms = createAsyncThunk(
  '/treasuryPrograms',
  async () => {
    const response = await fetchTreasuryPrograms();
    return response.data;
  }
);



export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    connect: (state: IAppState) => {

    },
    connected: (state: IAppState) => {
      state.connected = true;
    },
    disconnect: (state: { connected: boolean; }) => {
      state.connected = false;
    },
    cotiPriceEventsSubscription: (state) => {

    },
    treasuryTotalsEventsSubscription: (state) => {

    },
    unsubscribe: (state, { payload }) => {

    },
    onTreasuryTotalsEvent: (state: IAppState, { payload }) => {
      state.treasury.totalCotiInPool = payload.totalCotiInPool;
      state.treasury.tvl = payload.tvl;
      state.treasury.maxTotalApy = payload.maxTotalApy;
    },
    onTreasuryPrograms: (state: IAppState, { payload }) => {
      state.treasury.program = payload.programs[0] ? payload.programs[0] : null;
    },
    onCotiPriceEvent: (state: IAppState, { payload }: any) => {
      state.treasury.cotiPrice = payload
    },
    setWindowDimensions: (state: IAppState, { payload }: { payload: { innerHeight: number; innerWidth: number; } }) => {
      const { innerHeight, innerWidth } = payload
      state.windowDimensions = {
        innerHeight,
        innerWidth,
        isMobile: innerWidth < 768,
        isPortraitTablet: innerWidth >= 768 && innerWidth < 1024,
        isTablet: innerWidth >= 1024 && innerWidth < 1369,
        isDesktop: innerWidth >= 1369,
        isPortrait: innerWidth < innerHeight
      }
    },
    resetApiError: (state: IAppState) => {
      state.apiError = null;
    },
    onApiError: (state: IAppState, { payload }: any) => {
      const { statusCode, message } = payload.response.data
      if (statusCode === 400) {
        state.apiError = message;
        return
      }
      state.apiError = "Something went wrong for more information please contact support@coti.io";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCotiPrice.fulfilled, (state, { payload }) => {
        state.treasury.cotiPrice = payload.price
      })
      .addCase(getCotiPrice.rejected, (state) => {
        state.treasury.cotiPrice = null
      })
      .addCase(getTreasuryStats.fulfilled, (state, { payload }) => {
        state.treasury.totalCotiInPool = payload.totalCotiInPool;
        state.treasury.tvl = payload.tvl;
        state.treasury.maxTotalApy = payload.maxTotalApy;
      })
      .addCase(getTreasuryStats.rejected, (state) => {
        state.treasury.totalCotiInPool = null;
        state.treasury.tvl = null;
        state.treasury.maxTotalApy = null;
      })
      .addCase(getTreasuryPrograms.fulfilled, (state, { payload }) => {
        state.treasury.program = payload.programs[0] ? payload.programs[0] : null;
      })
  },
});

export const {
  setWindowDimensions,
  onTreasuryTotalsEvent,
  onTreasuryPrograms,
  onCotiPriceEvent,
  connect,
  disconnect,
  connected,
  onApiError,
  resetApiError,
  cotiPriceEventsSubscription,
  treasuryTotalsEventsSubscription,
  unsubscribe
} = appSlice.actions;

export const selectApp = (state: RootState) => state.app;

export default appSlice.reducer;