export interface IAppState {
  treasury: {
    totalCotiInPool: string | null,
    tvl: string | null,
    maxTotalApy: string | null,
    cotiPrice: string | null,
    program: IPrograms | null;
  },
  windowDimensions: {
    innerHeight: number;
    innerWidth: number;
    isMobile: boolean;
    isPortraitTablet: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isPortrait: boolean;
  },
  connected: boolean;
  apiError: string | null;
}

// =======================================================================================
// Socket Events
// =======================================================================================

export enum SocketEvents {
  TreasuryTotalsUpdates = 'treasury_balance',
  CotiPrice = 'cotiPrice',
}

export interface IPrograms {
  depositedAmount: IDepositedAmount[];
  totalUsdValue: string | null;
}

export interface IDepositedAmount {
  assetSymbol: string;
  valueInUsd: string;
  valueInCoti: string;
}
