import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import React, { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import Image from 'components/Elements/Image';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import { selectApp } from 'features/App/app.slice';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './Djed.scss';

const Djed = () => {
  return (
    <>
      <HeaderDjed />
      <BlogCTA/>
    </>
  )
}

export default Djed;

const HeaderDjed = () => {
  const { header } = config.djed
  const { windowDimensions: { isMobile } } = useAppSelector(selectApp);
  const renderHeadline = useMemo(() => <>{header.headline[0]} {!isMobile && <span>{header.headline[1]}</span>} </>, [header, isMobile]);

  const renderChildren = useMemo(() => {
    const children = (<>
      {header.text.map((p,idx) => {
        return <p key={idx}>{p}</p>
      })}
        <SectionActions>
          <div className='cover_button'>
            <Button
              text={header.buttons.goto.text}
              gaEvent
              onClick={() => openInNewTab(`${process.env.REACT_APP_DJED}`)}
            />
          </div>
        </SectionActions>
    </>)
    return children;
  }, [header]);

  return useMemo(() => (
    <Section className='main header_djed'>
      <SectionContent className='content_inner_page' headline={renderHeadline} children={renderChildren}/>
      <Image className='section_image' image={`${header.image}${isMobile ? '_mobile' : ''}.png`} />
    </Section>
  ), [isMobile, header, renderHeadline, renderChildren]);
}