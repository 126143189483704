import { useCallback, useEffect } from "react";
import { useAppDispatch } from "redux/hooks";
import { setWindowDimensions } from "features/App/app.slice";
import { debounce } from 'lodash';

export const useDimensions = () => {
    const dispatch = useAppDispatch();
    
    //eslint-disable-next-line
    const debouncedHandleResize = useCallback(
        debounce((event: Event) => {
            const currentTarget = event.currentTarget as Window;
            dispatch(setWindowDimensions({innerHeight: currentTarget.innerHeight, innerWidth: currentTarget.innerWidth}))
        }, 500)
    ,[]);
    
    useEffect(() => {
      window.addEventListener('resize', debouncedHandleResize);
      return () => window.removeEventListener('resize', debouncedHandleResize);
    }, [dispatch, debouncedHandleResize]);
    
}