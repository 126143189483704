import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import React, { useMemo } from 'react';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import Image from 'components/Elements/Image';
import { useAppSelector } from 'redux/hooks';
import { selectApp } from 'features/App/app.slice';
import './ADA.scss';

const ADA = () => {
  const { header } = config.ada;
  const { windowDimensions: { isMobile } } = useAppSelector(selectApp);
  const imgName = useMemo(() => `${header.image}${isMobile ? '_mobile' : ''}.png`, [header, isMobile]);

  return useMemo(() => (
    <Section className='main header_ada'>
      <SectionContent className='content_inner_page' headline={header.headline} text={header.text}>
        <SectionActions>
          <Button
            className='ada_btn'
            text={header.buttons.ada.text}
            gaEvent
            onClick={() => openInNewTab(`${process.env.REACT_APP_ADAPAY}`)}
          />
        </SectionActions>
      </SectionContent>
      <Image className='section_image' image={imgName} />
    </Section>
  ), [header, imgName]);
}

export default ADA;
