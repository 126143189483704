import React from 'react';
import Image from 'components/Elements/Image';
import Section from 'components/Elements/Section';
import { config } from 'config/config';
import './NotFound.scss'
import Button from 'components/Elements/Button';
import { useNavigate } from 'react-router';

const NotFound = () => {
  const { header } = config.notFound
  const navigate = useNavigate();

  return (
    <Section className='main not_found'>
      <div className='content'>
        <div className='headline'>
          <h2>{header.headline}</h2>
          <Image className='section_image' image={`${header.image}.svg`} />
        </div>
        <p className='text'>
          {header.text.map((t, idx) => <span key={idx}>{t}</span>)}
        </p>
        <Button className='go_home_btn' text={header.buttons.home.text} onClick={() => navigate('/')}/>
      </div>
    </Section>
  )
}

export default NotFound;