import Section from 'components/Elements/Section';
import { SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import React, { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import Image from 'components/Elements/Image';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import { selectApp } from 'features/App/app.slice';
import './Business.scss';

const Business = () => {
  return (
    <>
      <BusinessHeader />
      <BlogCTA/>
    </>
  )
}

export default Business;

const BusinessHeader = () => {
  const { header } = config.business
  const { windowDimensions: { isMobile } } = useAppSelector(selectApp);
  const headline = useMemo(() => <>{header.headline[0]} <span>{header.headline[1]}</span> {header.headline[2]}</>, [header]);

  return useMemo(() => (
    <Section className='main header_business'>
      <SectionContent className='content_inner_page' headline={headline} text={header.text} />
      <Image className='section_image' image={`${header.image}${isMobile ? '_mobile' : ''}.png`} />
    </Section>
  ), [isMobile, header, headline]);
}
