import React, { lazy, Suspense, useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionContent } from 'components/Elements/Section/Section';
import { config } from 'config/config';
import { useAppSelector } from 'redux/hooks';
import Image from 'components/Elements/Image';
import { selectApp } from 'features/App/app.slice';
import './Community.scss';

const ButtonsCommunity = lazy(() => import('./ButtonsCommunity'));

const Community = () => {
  return (
    <div className='cover_community'>
      <HeaderCommunity />
      <Suspense fallback={<></>}>
        <ButtonsCommunity />
      </Suspense>
    </div>
  )
}

export default Community;

const HeaderCommunity = () => {
  const { header } = config.community
  const { windowDimensions: { isMobile } } = useAppSelector(selectApp);
  return useMemo(() => (
    <Section className='main header_community'>
      <SectionContent className='content_inner_page' headline={header.headline} text={header.text} />
      <Image className='section_image' image={`${header.image}${isMobile ? '_mobile' : ''}.png`} />
    </Section>
  ), [isMobile, header]);
}