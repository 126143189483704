import React, { useMemo } from 'react'
import './IFrame.scss';

type EmbedVideoTypes = {
    headline?: string;
    url: string;
}

const IFrame = ({headline, url}:EmbedVideoTypes) => {
    return useMemo(() => (
        <div className='iframe_component'>
            {headline && <h2 className='title'>{headline}</h2>}
            <div className="item" style={{ paddingBottom: "56.25%" }}>
                <iframe src={url} frameBorder="0" title={headline} />
            </div>
        </div>
    ), [headline, url])
}

export default IFrame;