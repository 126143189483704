import React, { useMemo } from 'react';
import './Value.scss';

type ValueTypes = {
  id?:string;
  value: string;
  prefix?: string;
  suffix?: string;
  className?: string;
}

const Value = ({ id, value, prefix, suffix, className }: ValueTypes) => {
  const isValueNA = value === 'N/A';
  return useMemo(() => (
    <div className={`value_component ${className ?? ''}`} id={id}>
        {!isValueNA && value && prefix && <span className='prefix'>{prefix}</span>}
        {value}
        {!isValueNA && value && suffix && <span className='suffix'>{suffix}</span>}
    </div>
  ), [id, className, isValueNA, prefix, suffix, value])
}

export default Value;