import React, { lazy, Suspense, useMemo, useEffect, useState, useRef } from 'react';
import { config } from 'config/config';
import Button from 'components/Elements/Button';
import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import ModalLayout from 'components/Elements/Modal';
import IFrame from 'components/Elements/IFrame';
import { IModalPosition } from 'components/Elements/Modal/Modal';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './Simulation.scss';

const SimulationInner = lazy(() => import('./SimulationInner'));

const Simulation = () => {
  return (
    <>
      <HeaderSimulation />
      <Suspense fallback={<></>}>
        <SimulationInner/>
      </Suspense>
      <BlogCTA/>
    </>
  )
}

export default Simulation;

const HeaderSimulation = () => {
  const { header } = config.simulation;
  const [modalDetails, setModalDetails] = useState<IModalPosition | null>(null);
  const dagSimulationYoutubeId = 'gKbXP4LPjEw';

  const onClickHandler = (event: any) => {
    event.stopPropagation()
    setModalDetails({ clientX: event.clientX, clientY: event.clientY });
  }

  const onClose = (event: any) => {
    event.stopPropagation()
    setModalDetails(null);
  }

  return useMemo(() => (
    <Section className='main header_simulation'>
      <SectionContent className='content_inner_page' headline={header.headline} >
        <SectionActions>
          <Button className='simulation_btn' text={header.buttons.simulation.text} onClick={(e) => onClickHandler(e)} />
        </SectionActions>
      </SectionContent>
      {modalDetails ? (
        <ModalLayout modalDetails={modalDetails} onClose={onClose} center>
          <IFrame url={`https://www.youtube.com/embed/${dagSimulationYoutubeId}?enablejsapi=1`} />
        </ModalLayout>
      ) : <></>}
    </Section>
  ), [header, modalDetails]);
}

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
