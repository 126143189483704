import React from 'react';
import './AML.scss';

const AmlFoundation = () => {
  return (
    <div id='aml' className="c8 c24 doc-content">
      <p className="s1">
        Anti-Money Laundering and Counter- Terrorist Financing Policy
      </p>
      <ol id="l1">
        <li data-list-text="1.">
          <p>
          Anti-Money Laundering and Counter-Terrorist Financing Prevention Policy (“AML Policy”) of the COTI Foundation (“Foundation”, “we”, “us” or “our”) 
          applies to all users accessing the website <a href="https://coti.foundation/" className="a" target="_blank" rel="noreferrer">
                    coti.foundation
                </a> and its sub-domains (the “Website”). This AML Policy also applies to all staff and any third party the
            Company might do business with.
          </p>
        </li>
        <li data-list-text="2.">
          <p>
            The principal requirements, obligations and penalties, on which the Foundation’s Systems and
            Controls are based, are contained in:
          </p>
          <ol id="l2">
            <li data-list-text="i.">
              <p>
                Virtual Asset (Service Providers) Act;
              </p>
            </li>
            <li data-list-text="ii.">
              <p>
                Mutual Funds Act (<b>MF Act</b>)
              </p>
            </li>
            <li data-list-text="iii.">
              <p>
                Securities Investment Business Act (<b>SIB Act</b>)
              </p>
            </li>
            <li data-list-text="iv.">
              <p>
                Anti-Money Laundering Regulations
              </p>
            </li>
            <li data-list-text="v.">
              <p>
                Proceeds of Crime Act
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="3.">
          <p>
              The below is a summary of the Any questions relating to this policy should be directed to
              directed to <a href="mailto:MLRO@coti.foundation" target="_blank" rel="noreferrer">MLRO@coti.foundation.</a>
          </p>
        </li>
        <li data-list-text="4.">
          <p>
            The purpose of this AML Policy is to articulate our commitment to detecting, preventing and
            reporting attempts to use the Foundation’s services, and website and the COTI digital tokens allocated by the Foundation and/or its affiliates (the
            "COTI Tokens") to illegally launder money (including by way of concealing the source of funds
            and avoiding payment of taxes), to finance illegal activities such as terrorism and drug
            trafficking, to commit fraud, etc. (all activities being "Prohibited Activities"). The Foundation will cooperate with any and all law enforcement requests
            and/or investigations, and any other relevant Government Authority.
          </p>
        </li>
        <li data-list-text="5.">
          <p>
            In the event we suspect that an activity or behaviour in the Website and/or the services we may
            offer from time to time associated with you as a user of the
            Website may meet the characteristics of Prohibited Activities or any suspicious activities,
            we reserve the right to report such Prohibited Activities to the relevant Government
            Authority, with or without prior notice in compliance with the applicable laws and regulations, without your knowledge.
          </p>
        </li>
        <li data-list-text="6.">
          <p>
            The decision of whether a activity is potentially suspicious as Prohibited
  A         ctivities shall be made by the Foundation at its sole and absolute discretion.
          </p>
        </li>
        <li data-list-text="7.">
          <p>
            In compliance with local and international legislation and regulations, we have:
          </p>
          <ol id="l3">
            <li data-list-text="i.">
              <p>
                Appointed a Money Laundering Reporting Officer;
              </p>
            </li>
            <li data-list-text="ii.">
              <p>
                Adopted a risk-based approach towards assessing and managing the money
                laundering and terrorist financing risks to the business;
              </p>
            </li>
            <li data-list-text="iii.">
              <p>
              Adopted a risk-based customer and provider due diligence system and
              thereunder we obtain identification procedures where needed, which include
              enhanced due diligence checks in cases that we consider higher risk - the risk
              factor will be determined at our sole discretion;
              </p>
            </li>
            <li data-list-text="iv.">
              <p>
                flagging and reporting suspicious activity;
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="8.">
          <p>
            If you are a Forbidden Territory User you will not be able to participate in the any of our
            services nor use the Website, and you are not allowed to hold any COTI Tokens. “Forbidden
            Territory User” means, citizen of, resident of, a person located or domiciled in, or any
            corporation or partnership created or organized in or under the laws of a Restricted
            Jurisdiction, as this term is defined in the <a href="https://coti.foundation/terms" className="a" target="_blank" rel="noreferrer">
                  COTI Foundation
                  </a> Terms of Use. 
          </p>
        </li>
        <li data-list-text="9.">
          <p>
            In addition, the Foundation may not allow you to participate in any of its services and
            activities, if you are a citizen of, resident of, a person located or domiciled in, or a corporation
            or partnership created or organized in or under the laws of other jurisdictions that are included
            from time to time in international lists of countries at risk of money laundering.
          </p>
        </li>
        <li data-list-text="10.">
          <p>
            You agree and acknowledge that you will not, in connection with our services, use the
            Website; (i) in a manner that violates any applicable state, local, or international law or
            regulation; (ii) to fund terrorism or other criminal activity; (iii) to circumvent any export
            restrictions or economic sanctions; or (iv) to engage in unlawful money transmission,
            currency exchanging, or money laundering.
          </p>
        </li>
        <li data-list-text="11.">
          <p>
          As part of our AML Policy, you understand that while you agree to use the Website, you are
          also prepared to provide, any documentation which we find relevant in order to serve our AML policies.
          </p>
        </li>
        <li data-list-text="12.">
          <p>
             We may also disclose any information to any relevant
            authority and/or institution and/or third-party service providers in order to examine, inter alia,
            your status concerning bankruptcy, reputation checks and criminal records and/or in order to
            comply with AML laws, regulations, rules or anything related thereto.
          </p>
        </li>
        <li data-list-text="13.">
          <p>
            We restrict certain jurisdictions, type of businesses and certain individuals from using the
            Website, our services and holding the COTI Tokens. The AML Policy is not a substitute for
            your need to comply, in full, with the applicable laws and regulations in connection with your
            business conduct, personal characteristics and legal status. We do not offer any consultancy in
            connection with your need to comply with the applicable laws and regulations.
          </p>
        </li>
        <li data-list-text="14.">
          <p>
            The Foundation does not permit the use of anonymous and/or duplicate/multiple
            accounts/wallets, or the use of VPN.
          </p>
        </li>
        <li data-list-text="15.">
          <p>
            We may change this policy, at our own discretion, from time to time in compliance with the
            dynamic applicable laws and regulation framework, without notice.
          </p>
        </li>
      </ol>
    </div>
  )
}

export default AmlFoundation