import React, { useCallback, useMemo, useState } from 'react';
import Button from 'components/Elements/Button';
import IFrame from 'components/Elements/IFrame';
import ModalLayout, { IModalPosition } from 'components/Elements/Modal/Modal';
import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import Video from 'components/Elements/Video';
import { config, routes } from 'config/config';
import { useAppSelector } from 'redux/hooks';
import { selectApp } from 'features/App/app.slice';
import { useNavigate } from 'react-router';
import { openInNewTab } from 'utilities/utils';

const HomepageHeader = () => {
    const { enterprise } = config.homepage
    const [ modalDetails, setModalDetails ] = useState<IModalPosition | null>(null);
    const {windowDimensions: { isDesktop, isMobile, isPortrait }} = useAppSelector(selectApp);
    const navigate = useNavigate();
    const video = useMemo(() => `${process.env.PUBLIC_URL}/videos/${isDesktop ? 'loop-logo-wave.webm' : (isMobile && isPortrait) ? 'coti-flag-mobile.mp4' : 'coti-flag-tablet.mp4'}`, [isMobile, isDesktop, isPortrait])
    const videoPoster = useMemo(() => isDesktop ? 'flag_poster_desktop.png' : (isMobile && isPortrait) ? 'flag_poster_mobile.png' : 'flag_poster_tablet.png', [isDesktop, isMobile, isPortrait])
    
    const howCotiWorksYoutubeId = 'CXo7i_gdNR0';

    const onClickHandler = useCallback((event:any)=> {
        event.stopPropagation()
        navigate(routes.community.path as string);
        // setModalDetails({clientX: event.clientX, clientY: event.clientY});
    },[navigate]);
    

    const onClose = (event:any)=> {
        event.stopPropagation()
        setModalDetails(null);
    }
    return useMemo(() => (
        <Section className='main enterprise'>
            <SectionContent headline={enterprise.headline} text={enterprise.text}>
                <SectionActions>
                {/*<Button*/}
                {/*    text={enterprise.buttons.learn.text}*/}
                {/*    gaEvent*/}
                {/*    onClick={() => openInNewTab(`${process.env.REACT_APP_WALLET}`)} />*/}
                <Button
                    text={enterprise.buttons.community.text}
                    gaEvent
                    onClick={onClickHandler}
                />
                 <Button
                    className="secondary_btn"
                    text={enterprise.buttons.whitePaper.text}
                    gaEvent
                    onClick={() =>
                        openInNewTab(
                        `${process.env.PUBLIC_URL}/files/coti_v2_whitepaper.pdf`
                        )
                    }
            />
                </SectionActions>
                
            </SectionContent>

            <Video className='flag-video' poster={videoPoster} src={video} autoPlay loop/>

            {modalDetails ? (
                <ModalLayout modalDetails={modalDetails} onClose={onClose} center>
                    <IFrame url={`https://www.youtube.com/embed/${howCotiWorksYoutubeId}?enablejsapi=1`} />
                </ModalLayout>
            ) : <></>}
        </Section>
    ), [enterprise, video, modalDetails, onClickHandler, videoPoster]);
}

export default HomepageHeader;