import React, { lazy, Suspense, useMemo } from 'react';
import Section from 'components/Elements/Section';
import { SectionActions, SectionContent } from 'components/Elements/Section/Section';
import { config, socialNetworks, routes } from 'config/config';
import { useAppSelector } from 'redux/hooks';
import Image from 'components/Elements/Image';
import Button from 'components/Elements/Button';
import { openInNewTab } from 'utilities/utils';
import { selectApp } from 'features/App/app.slice';
import BlogCTA from 'components/Elements/CTA/Blog/BlogCTA';
import './Trustchain.scss';

const PaymentRequest = lazy(() => import('./PaymentRequest'));

const Trustchain = () => {
  return (
    <>
      <TrustchainHeader />
      <Suspense fallback={<></>}>
        <PaymentRequest />
      </Suspense>
      <BlogCTA />
    </>
  )
}

export default Trustchain;

const TrustchainHeader = () => {
  const { header } = config.trustchain
  const {windowDimensions: {isMobile}} = useAppSelector(selectApp);
  const imgName = `${header.image}.svg`

  const renderChildren = useMemo(() => {
    const children = (<>
      {header.text.map((p, idx) => {
        return <p key={idx} className={`${idx}`}>{p}</p>
      })}
      <SectionActions>
        <Button
          className='trustchain_btn'
          text={header.buttons.trustchain.text}
          gaEvent
          onClick={() => openInNewTab(`${process.env.PUBLIC_URL}/files/COTI-technical-whitepaper.pdf`)}
        />
        <Button
          text={header.buttons.github.text}
          className='secondary_btn'
          gaEvent
          onClick={() => openInNewTab(routes.github.link ?? '')}
          icon={socialNetworks.github.icon ? <Image image={socialNetworks.github.icon} /> : ''}
        />
      </SectionActions>
    </>)
    return children;
  }, [header]);

  return useMemo(() => (
    <Section className='main header_trustchain'>
      <SectionContent className='content_inner_page' headline={header.headline} children={renderChildren} />

      {isMobile ? <Image className='section_image' image={imgName}/> : <></>}

    </Section>
  ), [header, renderChildren, imgName, isMobile]);
}


