import React, { lazy, Suspense, useEffect, useMemo } from "react"
import { useDimensions, useScrollToTop } from "hooks"
import { Route, Routes, useLocation } from "react-router-dom"
import { bannerConfig, isFoundationWebsite, routes } from "config/config"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { connect, resetApiError, selectApp } from "./app.slice"
import PageLayout from "components/PageLayout"
import Header from "components/Header"
import Homepage from "./pages/Homepage"
import NotFound from "./pages/NotFound"
import MultiDag from "./pages/MultiDag"
import Trustchain from "./pages/Trustchain"
import Videos from "./pages/Videos"
import Blog from "./pages/Blog"
import Treasury from "./pages/Treasury"
import Wallet from "./pages/Wallet"
import Business from "./pages/Business"
import Djed from "./pages/Djed"
import CVI from "./pages/CVI"
import CotiCoin from "./pages/CotiCoin"
import Team from "./pages/Team"
import Community from "./pages/Community"
import Careers from "./pages/Careers"
import ADA from "./pages/ADA"
import HelpCenter from "./pages/HelpCenter"
import Legal from "./pages/Legal"
import Simulation from "./pages/Simulation"
import { useDocumentTitle } from "hooks"
import useAnalytics from "utilities/useAnalytics"
import Foundation from "./pages/Foundation"
import Banner from "components/Elements/Banner"

const Footer = lazy(() => import("components/Footer"))

function App() {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { apiError, connected } = useAppSelector(selectApp)
  useDimensions()
  useScrollToTop()
  useDocumentTitle()
  useAnalytics()

  const showBanner = useMemo(
    () =>
      !isFoundationWebsite &&
      bannerConfig.airDrop.showInPath.find(
        (path) => location.pathname === path
      ),
    [location.pathname]
  )

  useEffect(() => {
    if (!connected) dispatch(connect())
  }, [dispatch, connected])

  useEffect(() => {
    if (apiError) {
      setTimeout(() => {
        dispatch(resetApiError())
      }, 3500)
    }
  }, [apiError, dispatch])

  return (
    <div className="App">
      {showBanner ? (
        <Banner
          text={bannerConfig.airDrop.text}
          link={bannerConfig.airDrop.link}
        />
      ) : null}
      <Header />
      <PageLayout>
        <AppRoutes />
      </PageLayout>

      <Suspense fallback={<></>}>
        <Footer />
      </Suspense>
    </div>
  )
}

export default App

const AppRoutes = () => {
  return isFoundationWebsite ? (
    <Routes>
      <Route path={routes.home.path} element={<Foundation />} />
      <Route path={routes.termsOfService.path} element={<Legal />} />
      <Route path={routes.aml.path} element={<Legal />} />
      <Route path={routes.notFound.path} element={<NotFound />} />
    </Routes>
  ) : (
    <Routes>
      <Route path={routes.home.path} element={<Homepage />} />
      <Route path={routes.foundation.path} element={<Foundation />} />
      <Route path={routes.trustchain.path} element={<Trustchain />} />
      <Route path={routes.multidag.path} element={<MultiDag />} />
      <Route path={routes.videos.path} element={<Videos />} />
      <Route path={routes.blog.path} element={<Blog />} />
      <Route path={routes.treasury.path} element={<Treasury />} />
      <Route path={routes.wallet.path} element={<Wallet />} />
      <Route path={routes.business.path} element={<Business />} />
      <Route path={routes.djed.path} element={<Djed />} />
      <Route path={routes.cvi.path} element={<CVI />} />
      <Route path={routes.coin.path} element={<CotiCoin />} />
      <Route path={routes.team.path} element={<Team />} />
      <Route path={routes.community.path} element={<Community />} />
      <Route path={routes.careers.path} element={<Careers />} />
      <Route path={routes.adapay.path} element={<ADA />} />
      <Route path={routes.help.path} element={<HelpCenter />} />
      <Route path={routes.aml.path} element={<Legal />} />
      <Route path={routes.termsOfService.path} element={<Legal />} />
      <Route path={routes.privacyPolicy.path} element={<Legal />} />
      <Route path={routes.simulation.path} element={<Simulation />} />
      <Route path={routes.notFound.path} element={<NotFound />} />
    </Routes>
  )
}
