import React, { useEffect, useMemo, useRef } from 'react';

interface IVideoTypes {
    className?: string;
    src: string;
    autoPlay?: boolean;
    poster?: string;
    controls?: boolean;
    muted?: boolean;
    loop?: boolean;
    play?: boolean;
    children?: JSX.Element[] | JSX.Element | string;
    playsInline?: boolean;
}

const Video = ({ 
    src,
    className, 
    autoPlay = false, 
    poster = '', 
    controls = false, 
    muted = true, 
    children, 
    loop = false, 
    play = false,
    playsInline = true, }: IVideoTypes) => {
    const vidRef = useRef() as React.MutableRefObject<HTMLVideoElement>;

    useEffect(() => {
        if (!play || !vidRef.current) return;
        vidRef.current.play();
    }, [play])

    return useMemo(() => (
        <div className={`video ${className ?? ''}`}>
            <video
                ref={vidRef}
                loop={loop}
                autoPlay={autoPlay}
                poster={poster && require(`images/${poster}`)}
                controls={controls}
                muted={muted}
                playsInline={playsInline}
            >
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {children}
        </div>
    ), [className, loop, autoPlay, poster, controls, muted, src, children, playsInline]);
}

export default Video;