import React, { FC } from "react"
import "./Banner.scss"

type Props = {
  text: string
  link: string
}

const Banner: FC<Props> = ({ text, link }) => {
  return (
    <div className="banner">
      {text}
      <a href={link} target="_blank" rel="noopener noreferrer nofollow">
        Learn more
      </a>
    </div>
  )
}

export default Banner
