import React, { useCallback, useMemo } from 'react';
import { sendGAEvent } from 'utilities/ga4';
import './Button.scss';

export type ButtonTypes = {
  props?: any;
  text?: string;
  className?: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: JSX.Element[] | JSX.Element | string;
  icon?: JSX.Element[] | JSX.Element | string;
  gaEvent?: boolean;
}

const Button = ({ props, text, className, disabled, onClick, children, icon, gaEvent }:ButtonTypes) => {
    const onClickHandler = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (gaEvent) {
            sendGAEvent(`"${text}" button click`);
        }
        onClick(e);
    },[gaEvent, onClick, text])
    
    return useMemo(() => (
        <button
            type='button'
            onClick={onClickHandler}
            {...props}
            disabled={disabled}
            className={`button_component ${className ?? ''}`}
        >
            {icon}
            {text}
            {children}
        </button>
    ), [onClickHandler, props, disabled, className, icon, text, children])
}

export default Button;