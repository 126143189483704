import React, { useMemo } from "react"
import Logo from "components/Elements/Logo"
import Button from "components/Elements/Button"
import { useAppSelector } from "redux/hooks"
import { selectApp } from "features/App/app.slice"
import Menu from "components/Menu"
import { isFoundationWebsite, routes } from "config/config"
import { openInNewTab } from "utilities/utils"
import { useScrollDirection } from "hooks"
import { ScrollDirection } from "hooks/useScrollDirection"
import "./Header.scss"

const Header = () => {
  const {
    windowDimensions: { isDesktop, innerHeight },
  } = useAppSelector(selectApp)
  const { scrollPosition, scrollDir } = useScrollDirection()
  const isFixedHeader = useMemo(
    () => scrollDir === ScrollDirection.up && scrollPosition > innerHeight / 4,
    [innerHeight, scrollDir, scrollPosition]
  )

  return useMemo(
    () => (
      <div
        className={`header_component ${isFixedHeader ? "fixed_header" : ""}`}
      >
        <div className="header_content">
          <Logo linkTo={routes.home.path} />
          <Menu />
          {isDesktop && !isFoundationWebsite && (
            <Button
              text="Create Account"
              className="white_btn"
              gaEvent
              onClick={() => openInNewTab(`${process.env.REACT_APP_WALLET}`)}
            />
          )}
        </div>
      </div>
    ),
    [isDesktop, isFixedHeader]
  )
}

export default Header
