import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    categories,
    cotiWebsite,
    INavCategory,
    INavItem,
    isFoundationWebsite,
    routes as routesConfig
} from 'config/config';
import { useAppSelector } from 'redux/hooks';
import { selectApp } from 'features/App/app.slice';
import MenuButton from './MenuButton/MenuButton';
import { openInNewTab } from 'utilities/utils';
import Button from 'components/Elements/Button';
import { sendGAEvent } from 'utilities/ga4';
import './Menu.scss';

type iNavRoutesTypes = {
    category:INavCategory;
    onNavigate: (path:string, label: string) => void;
    isExpanded: boolean;
    onExpand: (path:string) => void;
}

type INavItemTypes = {
    label: string;
    className?: string;
    onClick: () => void;
}

type ICategoryRoutesTypes = {
    routes:INavItem[] | undefined;
    onClick: (path?:string, link?: string, isFoundationRoute?: boolean) => void
}

const Menu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const active = location.pathname;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [expandedCategory, setExpandedCategory] = useState('');
    const {windowDimensions: {isDesktop}} = useAppSelector(selectApp);

    useEffect(() => {
        if (isMenuOpen) return;
        setExpandedCategory('');
    }, [isMenuOpen])

    const onClickNavigate = useCallback((path:string, label?: string) => {
        if (active !== path) {
            navigate(path)
            const route = Object.values(routesConfig).find(r => r.path === path);
            sendGAEvent(`[Navigation Panel] ${label ?? ''} -> ${route?.label} button click`);
        }
        setIsMenuOpen(false);
    },[active, navigate])

    useEffect(()=> {
        document.body.style.overflow = isMenuOpen ? 'hidden' : 'unset';
    },[isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen) {
            setIsMenuOpen(false)
        }
    // eslint-disable-next-line
    }, [active])

    return useMemo(() => (
        <div className={`navbar_component ${isMenuOpen ? 'open' : ''}`}>
            {!isDesktop && <MenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
            <div className='content_wrapper'>
                <ul className='navbar_options'>
                    {Object.values(categories).map(category => {
                        return (
                            <React.Fragment key={category.label}>
                                {category.routes?.length ? (
                                    <NavRoutes
                                        category={category}
                                        onNavigate={onClickNavigate}
                                        isExpanded={expandedCategory === category.label}
                                        onExpand={setExpandedCategory}
                                    />
                                ) : (
                                    <NavItem
                                        className='nav_link'
                                        key={category.label}
                                        label={category.label}
                                        onClick={() => category.path && onClickNavigate(category.path)}
                                    />
                                )}
                            </React.Fragment>
                        )
                    })}
                </ul>

                {!isDesktop && isMenuOpen && !isFoundationWebsite &&(
                    <>
                        <div className='dash_underline'/>
                        <Button 
                            text='Create Account'
                            className='get_started white_btn'
                            gaEvent
                            onClick={() => openInNewTab(`${process.env.REACT_APP_WALLET}`)}
                        />
                    </>
                )}
            </div>
        </div>
  ), [isMenuOpen, isDesktop, onClickNavigate, expandedCategory])
}

export default Menu;

const NavRoutes = ({category:{label, routes}, onNavigate, onExpand, isExpanded}:iNavRoutesTypes) => {
    const {windowDimensions: {isDesktop}} = useAppSelector(selectApp);
    const onClickHandler = useCallback((path?:string, link?: string, isFoundationRoute?: boolean) => {
        if(isFoundationWebsite && !isFoundationRoute) {
            window.location.href = cotiWebsite + path;
            return
        }
        if (path) {
            return onNavigate(path, label)
        }
        if (link) {
            openInNewTab(link)
            const route = Object.values(routesConfig).find(r => r.link === link);
            sendGAEvent(`[Navigation Panel] ${label ?? ''} -> ${route?.label} button click`);
            return
        }
    } ,[label, onNavigate])
    
    return useMemo(() => (
        <li className={`nav_link ${isExpanded ? 'expanded' : ''}`} onClick={() => isExpanded ? onExpand('') : onExpand(label)}>
            <div className='route'>
                <div className='label'>
                    {label}
                    {!isDesktop && <div className='arrow_down' style={isExpanded ? {transform: "rotate(180deg)"} : {} } />}
                </div>
                {!isDesktop && isExpanded && <div className='underline'/>}
            </div>
            <CategoryRoutes routes={routes} onClick={onClickHandler}/>
            {!isDesktop && isExpanded && <div className='underline'/>}
        </li>
    ), [label, routes, isExpanded, isDesktop, onExpand, onClickHandler]);
}

const NavItem = ({label, className, onClick}: INavItemTypes) => useMemo(() => (
    <li className={className ?? ''} onClick={onClick}>
        {label}
    </li>
), [className, label, onClick])

export const CategoryRoutes = ({routes, onClick}: ICategoryRoutesTypes) => {
    const {windowDimensions: {isDesktop}} = useAppSelector(selectApp);
    const links = routes?.filter(route => route.link)
    const paths = routes?.filter(route => route.path)

    return useMemo(() => !isDesktop ? (
            <ul className='category_routes'>
                {routes?.map(route => <NavItem key={route.label} label={route.label} className='nav_route' onClick={() => onClick(route.path, route.link, route.isFoundation)}/>)}
            </ul>
        ) : (
            <div className={`category_routes ${paths?.length ? 'route_paths' : ''} ${links?.length ? 'route_links' : ''}`}>
                {paths?.length ? <ul className='paths'>
                    {paths.map((route: INavItem) => <NavItem key={route.label} label={route.label} className='nav_route' onClick={() => onClick(route.path, '', route.isFoundation)}/>)}
                </ul> : null}

                {links?.length ? <ul className='links'>
                    {links.map((route: INavItem) => <NavItem key={route.label} label={route.label} className='nav_route' onClick={() => onClick('', route.link)}/>)}
                </ul> : null}
            </div>
        )
    , [paths, links, isDesktop, routes, onClick]);
}