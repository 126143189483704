import ga4 from 'react-ga4'

const TRACKING_ID:string = process.env.REACT_APP_GA_TRACKING_ID || ''

export const init = () => ga4.initialize(TRACKING_ID)

export const sendGAEvent = (name: string) => ga4.event('screen_view', {
  app_name: "cotiMarketing",
  screen_name: name,
})

export const sendPageview = (path: string) => ga4.send({ 
  hitType: 'pageview', 
  page: path 
})