import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { routes } from 'config/config';

export const useDocumentTitle = (title?: string) => {
    const location = useLocation();
    const appRoutes = Object.values(routes).filter(r => r.path);
    const currentRoute = useMemo(() => appRoutes.find(route => route.path === location.pathname), [appRoutes, location.pathname]);

    useEffect(() => {
        if (title) {
            document.title = title;
            return
        }
        if (currentRoute?.label) {
            document.title = title ?? `COTI - ${currentRoute.label}`;
        }
    }, [title, currentRoute]);
};