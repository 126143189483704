import axios, { AxiosInstance } from 'axios';

export const {
    REACT_APP_TREASURY_API: TREASURY_API,
    REACT_APP_TREASURY_ERC_API: TREASURY_ERC_API,
    REACT_APP_TREASURY_WS: TREASURY_WS,
    REACT_APP_TREASURY_ERC_WS: TREASURY_ERC_WS
} = process.env;

const headers = {
    "Content-type": "application/json"
}

export const treasuryApi: AxiosInstance = axios.create({
    baseURL: TREASURY_API,
    headers
})

export const treasuryErcApi: AxiosInstance = axios.create({
    baseURL: TREASURY_ERC_API,
    headers
})

const api = {
    getCotiPrice: () => treasuryApi.get(`/get-coti-price`),
    getTreasuryStats: () => treasuryApi.get(`/get-total`),
    getTreasuryPrograms: () => treasuryErcApi.get(`/programs`),
}

export default api;