import { io, Socket } from 'socket.io-client'
import { TREASURY_WS } from './api';

export default class SocketClient {
  
  socket: Socket | null | undefined

  connect() {
    if(!TREASURY_WS) return;
    this.socket = io(TREASURY_WS, {transports: ["websocket"]});
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect()
      this.socket = null
    }
  }

  emit(eventName: string, data: any) {
    if (this.socket) {
      this.socket.emit(eventName, data)
    }
  }

  on(eventName: string, func: () => void) {
    if (this.socket) {
      this.socket.on(eventName, func)
    }
  }

  removeAllListeners(eventName: string) {
    if (this.socket) {
      this.socket.removeAllListeners(eventName);
    }
  }
}
