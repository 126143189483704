import { useEffect } from 'react';
import { IEventTypes, IRefTypes } from 'types/types';

export const useOnClickOutside = (ref:IRefTypes, handler:IEventTypes) => {
    useEffect(
        () => {
        const listener = (event: any) => {
            if (!ref.current || ref.current.contains(event.target)) return
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
        },
        [ref, handler]
    );
}