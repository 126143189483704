import React, { CSSProperties, useMemo } from 'react';
import './Image.scss';

type IImageTypes = {
    className?: string;
    style?: CSSProperties
    image:string;
    onClick?: () => void;
    animate?: boolean;
    alt?: string;
    children?: JSX.Element[] | JSX.Element | string;
}

const Image = ({ className, style, image, onClick, animate, alt, children }:IImageTypes) => {
    return useMemo(() => (
        <div className={`image_component ${animate ? 'animate': ''} ${className ?? ''} `} onClick={onClick}>
            <img src={require(`images/${image}`)} alt={alt ??''} style={style} />
            {children}
        </div>
    ), [animate, className, style, image, onClick, alt, children]);
}

export default Image;

